import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { GrFormAdd } from "react-icons/gr";
import {
  createDeals,
  DeleteDeal,
  getAllDeals,
  UpdateDealStatusApi,
} from "../api/DealsApi";
import Table from "react-bootstrap/Table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs-plugin-utc";
import { getDecryptedCookie } from "../utils/Utils";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration); // Extend duration plugin



const CountdownTimer = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      const now = dayjs();
      const end = dayjs(endTime);
      const diff = end.diff(now);

      if (diff <= 0) {
        setTimeLeft("Deal Expired");
        return;
      }

      const formattedTime = dayjs.duration(diff).format("DD[d] HH[h] mm[m] ss[s]");
      setTimeLeft(formattedTime);
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return <span>{timeLeft}</span>;
};

export const Deal = () => {
    const adminData = getDecryptedCookie("AdminData");
    const token = adminData?.token;
  
  const [DealsData, SetDealsData] = useState(null);

  const ConvertDateTimeFormat = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();
    const amOrPm = date.getHours() < 12 ? "AM" : "PM";
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amOrPm}`;

    return `${day} ${month} ${year} at ${formattedTime}`;
  };

  const fetchAllDeals = async () => {
    try {
      const response = await getAllDeals(token);
      if (response?.status === "success") {
        SetDealsData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDeals = async (data) => {
    try {
      const response = await DeleteDeal(data);
      if (response?.status === "success") {
        toast.success("Deal Deleted Successfully", { duration: 3000 });
        fetchAllDeals();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllDeals();
  }, []);

  const initialValues = {
    dealName: "",
    dealNameSlug: "",
    // products: "",
    startTime: "",
    endTime: "",
    dealType: "",
    DiscountPercentage: "",
    dealNameImage: null,
  };

  const validationSchema = Yup.object().shape({
    dealName: Yup.string().required("Deal Name is required"),
    dealNameSlug: Yup.string().required("Deal Name Slug is required"),
    startTime: Yup.string()
      .required("Start Time is required")
      .test(
        "is-today-or-later",
        "Start date cannot be in the past",
        (value) => {
          return dayjs(value).isSameOrAfter(dayjs(), "minute");
        }
      ),
    endTime: Yup.string()
      .required("End Time is required")
      .test(
        "is-after-start",
        "End date must be after the start date",
        function (value) {
          const { startTime } = this.parent;
          return dayjs(value).isAfter(dayjs(startTime), "minute");
        }
      ),
    dealType: Yup.string().required("Deal Type is required"),
    DiscountPercentage: Yup.number()
      .required("Discount Percentage is required")
      .min(0, "Discount must be at least 0")
      .max(100, "Discount cannot exceed 100"),
    dealNameImage: Yup.mixed().required("At least one image is required"),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const formData = new FormData();

      const convertToIST = (date) => {
        const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
        const istDate = new Date(new Date(date).getTime() + istOffset);
        return istDate.toISOString().slice(0, 19); // Keep YYYY-MM-DDTHH:mm:ss format
      };

      const formattedStartTime = convertToIST(values.startTime);
    const formattedEndTime = convertToIST(values.endTime);

      // const formattedStartTime = new Date(values.startTime).toISOString();
      // const formattedEndTime = new Date(values.endTime).toISOString();

      formData.append("dealName", values.dealName);
      formData.append("dealNameSlug", values.dealNameSlug);
      formData.append("startTime", formattedStartTime);
      formData.append("endTime", formattedEndTime);
      formData.append("dealType", values.dealType);

      if (
        values.DiscountPercentage !== undefined &&
        values.DiscountPercentage !== null
      ) {
        formData.append("DiscountPercentage", values.DiscountPercentage);
      }

      if (values.dealNameImage) {
        Array.from(values.dealNameImage).forEach((file) => {
          formData.append("dealNameImage", file);
        });
      }

      // Debugging the form data
      for (const pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      const response = await createDeals(formData);

      if (response?.status === "success") {
        toast.success("Deal Created Successfully", { duration: 3000 });
        actions.resetForm();
        fetchAllDeals();
      } else {
        toast.error(response?.message || "Failed to create deal", {
          duration: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
    actions.setSubmitting(false);
  };

  const handleUpdateDealStatus = async (dealId, status) => {
    try {
      // const response = await fetch(`${Base_Url}/deal/editdeal`, {
      //   dealId,
      //   status,
      // });

      const formData = {
        dealId,
        status,
      };

      const response = await UpdateDealStatusApi(formData);

      if (response?.status === "success") {
        toast.success("Deal status updated successfully", { duration: 3000 });
        fetchAllDeals(); // Refresh deals data
      } else {
        toast.error(response?.data?.message || "Failed to update deal status");
      }
    } catch (error) {
      console.error("Error updating deal status:", error);
      toast.error("An error occurred while updating the deal status.");
    }
  };

  return (
    <div className="container">
      <Toaster />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Deals Management
            </span>
          </div>
          <div></div>
        </div>
      </div>

      <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Deal Name</th>
              <th>Deal Name Slug</th>
              <th>Deals Start Time</th>
              <th>Deals End Time</th>
              <th>Time Left</th>
              <th>Deals Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(DealsData) && DealsData.length > 0 ? (
              DealsData?.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.dealName}</td>
                  <td>{ele?.dealNameSlug}</td>
                  <td>{ConvertDateTimeFormat(ele?.startTime)}</td>
                  <td>{ConvertDateTimeFormat(ele?.endTime)}</td>
                  <td><CountdownTimer endTime={ele?.endTime} /></td>
                  <td>
                    {/* Button to toggle deal status */}
                    <button
                      onClick={() =>
                        handleUpdateDealStatus(
                          ele?._id,
                          ele?.status === "Active" ? "Disable" : "Active"

                          // "Active", "Disable
                        )
                      }
                      className="btn btn-primary btn-sm"
                    >
                      {ele?.status === "Active" ? "Disable" : "Active"}
                    </button>
                  </td>
                  <td onClick={() => handleDeleteDeals(ele?._id)}><b className="delete_btn_deals">Delete</b></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className="row">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, handleChange, isSubmitting }) => (
            <Form>
              <div className="form_main_div">
                <div className="row">
                  <div className="col-md-6">
                    <div className="Form_Field_Heading">
                      Deal Name
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="dealName"
                      className="form-control Form_Field_Input"
                    />
                    <ErrorMessage
                      name="dealName"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-md-6">
                    <div className="Form_Field_Heading">
                      Deal Name Slug
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="dealNameSlug"
                      className="form-control Form_Field_Input"
                      onChange={(e) => {
                        const slug = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z-]/g, "")
                          .replace(/-+/g, "-");
                        setFieldValue("dealNameSlug", slug);
                      }}
                    />
                    <ErrorMessage
                      name="dealNameSlug"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-6 mt-1">
                    <label>Start Time</label>
                    <Field
                      type="datetime-local"
                      name="startTime"
                      className="form-control"
                      min={dayjs().format("YYYY-MM-DDTHH:mm")}
                    />
                    <ErrorMessage
                      name="startTime"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-6 mt-1">
                    <label>End Time</label>
                    <Field
                      type="datetime-local"
                      name="endTime"
                      className="form-control"
                      min={
                        values.startTime || dayjs().format("YYYY-MM-DDTHH:mm")
                      }
                    />
                    <ErrorMessage
                      name="endTime"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>
                  <div className="col-6 mt-1">
                    <label>Deal Type</label>
                    <Field
                      type="text"
                      name="dealType"
                      className="form-control Form_Field"
                      placeholder="Enter Deal Type"
                    />
                    <ErrorMessage
                      name="dealType"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-6 mt-1">
                    <label>Upload Deal Images</label>
                    <input
                      type="file"
                      name="dealNameImage"
                      multiple
                      className="form-control"
                      onChange={(e) => {
                        setFieldValue("dealNameImage", e.target.files);
                      }}
                    />
                    <ErrorMessage
                      name="dealNameImage"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="Form_Field_Heading">
                      Discount Percentage
                      <span>*</span>
                    </div>
                    <Field
                      type="number"
                      name="DiscountPercentage"
                      className="Form_Field_Input"
                    />
                    <ErrorMessage
                      name="DiscountPercentage"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-md-12 mt-1">

<button
  type="submit"
  className="deal_btn_btn mt-2"
  disabled={isSubmitting}
>
  Create Deal
  <GrFormAdd style={{ fontWeight: "800", fontSize: "20px" }} />
</button>
</div>

                </div>
                
                
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
