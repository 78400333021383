import { Base_Url } from "../constant/Url";

export const getallContactRequest = async (token) => {
  try {
    const res = await fetch(`${Base_Url}/contactus/getallcontactusrequest`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Include token
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleContactRequest = async (requestid,token) => {
  try {
    const res = await fetch(`${Base_Url}/contactus/getsinglecontactusrequest/${requestid}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Include token
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
