import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Toaster, toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Base_Url } from "../constant/Url";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GetAllUsersApi, UpdateUserStatusByAdmin } from "../api/UserDataApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const UsersData = () => {
  const [userData, setUserData] = useState([]);

  const adminData = getDecryptedCookie("AdminData");
  const token = adminData?.token;

  const FetchAllUser = async () => {
    try {
      const res = await GetAllUsersApi(token);
      if (res?.status === "success") {
        setUserData(res?.data);
      }
      console.log(res, "GetAllUsersApiGetAllUsersApi");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllUser();
  }, []);

  const navigate = useNavigate();

  const HandleViewUser = (data) => {
    navigate(`${data?._id}`);
  };

  const handleStatusChange = async (userId, newStatus) => {
    const res = await UpdateUserStatusByAdmin(userId, newStatus, token);
    if (res.status === "success") {
      toast.success("User status updated successfully!");
      FetchAllUser(); // Refresh user data
    } else {
      toast.error(res.message || "Failed to update status.");
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="category_managment_title_div">
              <h1>User Management</h1>
            </div>
          </div>
        </div>

        <div className="row table_user_data" style={{ overflow: "auto" }}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Profile</th>
                <th>User Information</th>
                <th>Date</th>
                <th>Details</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(userData) && userData.length > 0 ? (
                userData
                  .filter((ele) => ele?.status !== 2)
                  .map((ele, index) => (
                    <tr key={ele?._id}>
                      <td>{index + 1}</td>
                      <td className="d-flex align-items-center">
                        <img
                          src={`https://cdn.quality.healfate.in/astro_docs/2411081207444482No-image-found.png`}
                          alt=""
                          loading="lazy"
                          style={{ width: "100px", height: "60px" }}
                        />
                      </td>
                      <td>
                        <div>
                          <strong>User Id:</strong>
                          <span> {ele?._id}</span>
                        </div>
                        <div>
                          <strong>User Name:</strong>
                          <span> {ele?.name}</span>
                        </div>
                        <div>
                          <strong>User Mobile:</strong>
                          <span> {ele?.mobile}</span>
                        </div>
                        <div>
                          <strong>User Email:</strong>
                          <span> {ele?.email}</span>
                        </div>
                        <div>
                          <strong>GST Numbers:</strong>
                          <span> {ele?.gstnumber?.length} Nos.</span>
                        </div>
                        <div>
                          <strong>User Addresses:</strong>
                          <span> {ele?.addresses?.length} Nos.</span>
                        </div>
                      </td>

                      <td>
                        <div>
                          Account Created Date -
                          <span className="pe-2">
                            {new Date(ele.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </span>
                          <span>
                            {new Date(ele.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        </div>

                        <div>
                          Account Updated Date -
                          <span className="pe-2">
                            {new Date(ele.updatedAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </span>
                          <span>
                            {new Date(ele.updatedAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </span>
                        </div>
                      </td>

                      <td>
                        <Button onClick={() => HandleViewUser(ele)}>
                          View Details
                        </Button>
                      </td>

                      <td>
                        <select
                          value={ele?.status}
                          onChange={(e) =>
                            handleStatusChange(ele?._id, e.target.value)
                          }
                          className={`status-select ${ele?.status}`}
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="blocked">Blocked</option>
                        </select>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="12">
                    <Skeleton height={400} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
