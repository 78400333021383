import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";
import { GetSingleUserTicket, ReplyUserTicket } from "../api/TicketApi";
import { Toaster, toast } from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Base_Url, cdn_url } from "../constant/Url";
import "../styles/ReplyTicket.css";
// import chaticonimg2 from "../assets/images/image/chaticon2.png";

import chaticonimg2 from "../asset/icons/chaticon2.png"


export const TicketDetails = () => {
  const { ticketId } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const adminData = getDecryptedCookie("AdminData");
  const token = adminData?.token;
  const [loading, setLoading] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);

  const FetchSingleTicketDetails = async () => {
    setLoading(true);
    try {
      const res = await GetSingleUserTicket(ticketId, token);
      console.log(res, "GetSingleUserTicketGetSingleUserTicket");
      if (res?.status === "success") {
        setTicketDetails(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchSingleTicketDetails();
  }, []); // Add dependencies

  //   Reply Ticket

  const validationSchema = Yup.object({
    message: Yup.string().required("message is required"),
  });

  const initialValues = {
    message: "",
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
    console.log(files);
  };

  const handleTicketReply = async (values, actions) => {
    try {
      //   const token = userData?.token;


      const formData = new FormData();
      formData.append("message", values.message);

      selectedImages.forEach((image) => {
        formData.append("images", image);
      });

      console.log(formData, "formDataformData");
      console.log(token, "tokentoken");
      console.log(ticketId, "ticketIdticketId");

      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const res = await ReplyUserTicket(ticketId, token, formData);






      if (res?.status === "success") {
        toast.success(res?.message, { autoClose: 3000 });
        actions.resetForm();
        FetchSingleTicketDetails();
      } else if (
        res?.message === "Replies are allowed only if the ticket is open"
      ) {
        toast.error(res?.message, { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }

    actions.setSubmitting(false); // Enable the submit button after process
  };

  return (
    <>
      <Toaster />

        {/* static pages update section start */}
        <section className="static_page_update_section">
        {/* container start */}
        <div className="container">
          
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start  */}
            <div className="col-lg-10 col-md-10 col-sm-12 col-12">
              {/* static page title div start */}
          <div className="static_page_title_div">
          <h1>Ticket Details</h1>
          </div>
          {/* static page title div end */}
            <div className="fixed_main_div">
                {/*  */}
                <div className="tckt_chat_box_main_div">
                  <div>
                    <div>
                      <strong>Subject: </strong>
                      {ticketDetails?.subject}
                    </div>
                    <div>
                      <strong>Description: </strong>
                      {ticketDetails?.description}
                    </div>
                  </div>
                  {loading ? (
                    <Skeleton height={350} />
                  ) : ticketDetails?.replies?.length ? (
                    <div>
                      {ticketDetails?.replies?.map((reply, index) =>
                        reply.user === ticketDetails?.user ? (
                          <div key={index}>
                            <div className="tckt_msg_recevied_main_div">
                              <div className="receviedmsg_txt">
                                {reply.message}
                              </div>
                            </div>

                            <div className="ps-2" style={{ fontSize: "12px" }}>
                              <strong>
                                {new Date(reply?.createdAt)?.toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}{" "}
                                {new Date(reply?.createdAt)?.toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </strong>
                            </div>

                            <div className="tckt_msg_recevied_main_div">
                              <div className="sendmsg_img">
                                {reply?.images?.map((ele, index) => {
                                  return (
                                    <div key={index} className="py-1">
                                      <img
                                        src={`${cdn_url}${ele}`}
                                        alt=""
                                        className="img-fluid"
                                         loading="lazy"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="tckt_msg_send_main_div">
                              <div className="sendmsg_txt">{reply.message}</div>
                            </div>

                            <div
                              className="pe-2"
                              style={{ fontSize: "12px", textAlign: "end" }}
                            >
                              <strong>
                                {new Date(reply?.createdAt)?.toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}{" "}
                                {new Date(reply?.createdAt)?.toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </strong>
                            </div>

                            <div className="tckt_msg_send_main_div">
                              <div className="sendmsg_img">
                                {reply?.images?.map((ele, index) => {
                                  return (
                                    <div key={index} className="py-1">
                                      <img
                                        src={`${cdn_url}${ele}`}
                                        alt=""
                                        className="img-fluid"
                                         loading="lazy"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            {/* <div className="tckt_msg_send_main_div">
                              <div className="sendmsg_img">
                                <img
                                  src={chatimg2}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div> */}
                          </>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="text-center">No Chat Found</div>
                  )}
                </div>

                {/* chat footer input */}
                <div className="chat-footer2">
                  <Formik
                    initialValues={{ message: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleTicketReply}
                  >
                    {({ isSubmitting }) => (
                      <>
                        <Form className="chat-footer">
                          <div className="d-flex align-items-center w-100">
                            <Field
                              type="text"
                              name="message"
                              className="form-control flex-grow-1"
                              placeholder="Type a message"
                            />
                          </div>

                          <input
                            type="file"
                            id="imageUpload"
                            className="image_upload_input"
                            accept="image/*"
                            multiple
                            onChange={handleImageChange}
                          />
                          <label
                            htmlFor="imageUpload"
                            className="image_upload_label"
                          >
                            <img
                              src={chaticonimg2}
                              alt=""
                              className="img-fluid"
                            />
                          </label>

                          <button
                            type="submit"
                            className="btn btn-md"
                            disabled={isSubmitting}
                          >
                            Send
                          </button>
                        </Form>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-danger"
                        />
                      </>
                    )}
                  </Formik>
                </div>

                {/* chat footer input */}
              </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* static pages update section end */}
      

     
    </>
  );
};
