import axios from "axios";
import { Base_Url } from "../constant/Url";

export const getallCategory = async () => {
  try {
    const res = await fetch(`${Base_Url}/category/getallcategory`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const createCategory = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/category/createcategory`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating testimonial:", error);
    throw error;
  }
};

export const DeleteSingleCategory = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/category/deletesingleCategory/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleCategory = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/category/getsingleCategory/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    console.log(res);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleCategory = async (Category_id, formData) => {
  try {
    const response = await axios.put(
      `${Base_Url}/category/updatesingleCategory/${Category_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error while updating category:", error);
    throw error;
  }
};

export const FetchAllSubCategoriesWithCategories = async (data) => {
  try {
    const res = await fetch(
      `${Base_Url}/subcategory/getSubCategoriesByCategory`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // Ensure data is being passed correctly
      }
    );

    return await res.json();
  } catch (error) {
    console.log("An error occurred:", error);
  }
};
