import React, { useEffect, useState } from "react";
import { GrFormAdd } from "react-icons/gr";
import { Toaster, toast } from "react-hot-toast";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye } from "react-icons/io5";
import { Base_Url, cdn_url } from "../constant/Url";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "react-bootstrap/Modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import * as Yup from "yup";
import {
  createSocialMedia,
  DeleteSingleSocialMediaApi,
  getallSocialMedia,
  UpdateSingleSocialMedia,
} from "../api/SocialMediaApi";
import { Link } from "react-router-dom";

export const SocialMedia = () => {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(null);

  const [socialMediaData, setSocialMediaData] = useState([]);
  const [singleSocialMediaData, setSingleSocialMediaData] = useState(null);

  const [showSingleSocialMediaModal, setShowSingleSocialMediaModal] =
    useState(false);

  const handleSingleSocialMediaModalClose = () => {
    setSingleSocialMediaData(null); // Reset the state
    setShowSingleSocialMediaModal(false); // Close the modal
  };

  const handleSingleSocialMedia = async (ele) => {
    setSingleSocialMediaData(ele);
    console.log(ele);
    setShowSingleSocialMediaModal(true);
  };

  const [showUpdateSocialMediaModal, setShowUpdateSocialMediaModal] =
    useState(false);

  const handleUpdateSocialMediaModalClose = () => {
    setSingleSocialMediaData(null); // Reset the state
    setShowUpdateSocialMediaModal(false); // Close the modal
  };

  const handleUpdateSocialMedia = async (ele) => {
    setSingleSocialMediaData(ele);
    console.log(ele);
    // setPreview(ele?.image);

    setPreview(`${cdn_url}${ele?.image}`);

    setShowUpdateSocialMediaModal(true);
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("link", values.link);
    if (values.image && values.image instanceof File) {
      formData.append("image", values.image);
    } else {
      // Pass the existing image filename instead of omitting the key
      formData.append("image", values.image);
    }
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    try {
      const res = await UpdateSingleSocialMedia(
        singleSocialMediaData?._id,
        formData
      );
      if (res?.status === "success") {
        toast.success(res?.message, { autoClose: "3000" });
        handleUpdateSocialMediaModalClose();
        FetchAllSocialMedia();
      }
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const FetchAllSocialMedia = async () => {
    setLoading(true);
    try {
      const res = await getallSocialMedia();
      console.log(res);
      if (res?.status == "success") {
        setSocialMediaData(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSocialMedia = async (ele) => {
    try {
      const res = await DeleteSingleSocialMediaApi(ele?._id);
      if (res?.status === "success") {
        toast.success("Social Media Deleted Successfully!", { duration: 3000 });
        FetchAllSocialMedia();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllSocialMedia();
  }, []);

  const validationSchema = Yup.object({
    image: Yup.string().required("Social Media Name is required"),
    name: Yup.string().required("Social Media Image is required"),
    link: Yup.string().required("Social Media Url Link is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("image", values.image);
    formData.append("link", values.link);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createSocialMedia(formData);
      if (res?.data?.status === "success") {
        toast.success("Social Media Added Successfully!", { duration: 3000 });
        FetchAllSocialMedia();
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="container">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="row">
          <div className="justify-content-between">

<div className="category_managment_title_div">
             <h1> Social Media</h1>
             <button
                className="addbutton"
                // onClick={handleSocialMediaModalShow}
              >
                Add Social Media{" "}
                <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
              </button>
            </div>

           
            <div>
             
            </div>
          </div>
        </div>

        <div className="row">
          <Formik
            initialValues={{
              image: "",
              link: "",
              name: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Social Media Name
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="name"
                      className="form-control Form_Field_Input"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-md-6 Form_Field">
                    <div className="Form_Field_Heading">
                      Url Link
                      <span>*</span>
                    </div>
                    <Field
                      type="text"
                      name="link"
                      className="form-control Form_Field_Input"
                    />
                    <ErrorMessage
                      name="link"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>

                  <div className="col-md-12 Form_Field">
                    <div className="Form_Field_Heading">
                      Image
                      <span>*</span>
                    </div>

                    <input
                      type="file"
                      name="image"
                      className="form-control "
                      onChange={(event) => {
                        setFieldValue("image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-danger errormsg"
                    />
                  </div>
                </div>
                <div className="Form_Field_Submit_Button">
                  <button type="submit">Submit</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
<br></br>
        <div className="row">
        <div className="testimonials_table">
        <table className="table table-bordered">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Image</th>
                <th>Url</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                [...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton width={30} />
                    </td>
                    <td>
                      <Skeleton width={120} />
                    </td>
                    <td>
                      <Skeleton width={200} />
                    </td>
                    <td>
                      <Skeleton width={170} />
                    </td>
                    <td>
                      <Skeleton width={170} />
                    </td>
                  </tr>
                ))
              ) : Array.isArray(socialMediaData) &&
                socialMediaData?.length > 0 ? (
                socialMediaData.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.name}</td>

                    <td>
                      <img
                        src={`${cdn_url}${ele?.image}`}
                        alt={ele?.Name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      <Link
                        to={`${ele?.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ele?.link}
                      </Link>
                    </td>

                    <td>
                      <div className="d-flex align-items-center">
                        {/* <div
                          onClick={() => handleSingleSocialMedia(ele)}
                          className="action_button_view"
                        >
                          <IoEye />
                        </div> */}
                        <div
                          className="px-2 action_button_update"
                          onClick={() => handleUpdateSocialMedia(ele)}
                        >
                          <HiPencilSquare className="text-success" />
                        </div>
                        <div
                          className="action_button_delete"
                          onClick={() => handleDeleteSocialMedia(ele)}
                        >
                          <IoTrashBinOutline className="text-danger" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </div>

        {/* Single Social Media Modal */}

        <div className="row">
          <Modal
            show={showSingleSocialMediaModal}
            onHide={handleSingleSocialMediaModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Social Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="ps-2">
                  <strong>Social Media Name: </strong>
                  {singleSocialMediaData?.name} {/* Display name from state */}
                </div>
                <div className="ps-2">
                  <strong>Social Media Link: </strong>
                  <Link
                    to={`${singleSocialMediaData?.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {singleSocialMediaData?.link}
                  </Link>
                </div>
                <div className="SocialMedia_ImageDiv">
                  <strong>Social Media Image</strong>
                  <img
                    src={`${cdn_url}${singleSocialMediaData?.image}`} // Use the state to display the image
                    alt="Social Media"
                    className="SocialMedia_image"
                  />
                </div>
              </div>
              <div className="py-2">
                {singleSocialMediaData?.description}{" "}
                {/* Display description from state */}
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Update Single Social Media Modal */}

        <div className="row">
          <Modal
            show={showUpdateSocialMediaModal}
            onHide={handleUpdateSocialMediaModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Social Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  name: singleSocialMediaData?.name || "",
                  image: singleSocialMediaData?.image || null,
                  link: singleSocialMediaData?.link || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitUpdate}
              >
                {({ setFieldValue, values, isSubmitting }) => (
                 <div className="social_media_form_div">
                  <Form>
                    <div>
                      <div className="update_cate_label">Online Store Name</div>
                      <Field type="text" name="name" className="form-control Form_Field" />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div>
                      <div className="update_cate_label">Social Media Url</div>
                      <Field type="text" name="link" className="form-control Form_Field" />
                      <ErrorMessage
                        name="link"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <div className="update_cate_label">Online Store Image</div>
                      <input
                        type="file"
                        name="image"
                        className="form-control Form_Field"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("image", file);
                          if (file) {
                            setPreview(URL.createObjectURL(file)); // Show new preview
                          }
                        }}
                      />
                      <ErrorMessage
                        name="Image"
                        component="div"
                        className="errormsg text-danger"
                      />

                      {preview && (
                        <div
                          className="mt-2"
                          style={{ width: "100px", height: "100%" }}
                        >
                          <img
                            src={preview}
                            alt="Social Media Preview"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      )}
                    </div>

                    <Modal.Footer>
                      <button
                        className="close_btn_up"
                        onClick={handleUpdateSocialMediaModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="update_btn_up"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                    </Modal.Footer>
                  </Form>
                  </div>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};
