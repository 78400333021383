import React, { useEffect, useState } from "react";
import { getDecryptedCookie } from "../utils/Utils";
import { getallContactRequest } from "../api/ContactRequestApi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ContactRequest = () => {
  const adminData = getDecryptedCookie("AdminData");
  const token = adminData?.token;
  const navigate = useNavigate();

  const [contactRequest, setContactRequest] = useState([]);

  const [loading, setLoading] = useState(true);

  const FetchAllRequests = async () => {
    setLoading(true);
    try {
      const res = await getallContactRequest(token);

      if (res?.status === "success") {
        setContactRequest(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchAllRequests();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="contact_request_flex_div">
          <div className="contac_pg_title">
            <h2 className="text-center">Contact Request</h2>
          </div>
          <div className="contac_pg_length">
            <p>Total Request : {contactRequest?.length}</p>
          </div>
        </div>

        <div className="contact_rqst_page_table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>User Information</th>
                <th>Request Details</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                [...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton width={30} />
                    </td>
                    <td>
                      <Skeleton width={120} />
                      <Skeleton width={120} />
                      <Skeleton width={120} />
                    </td>
                    <td>
                      <Skeleton width={200} />
                      <Skeleton width={200} />
                      <Skeleton width={200} />
                      <Skeleton width={200} />
                    </td>

                    <td>
                      <Skeleton width={170} />
                    </td>
                    <td>
                      <Skeleton width={170} />
                    </td>
                  </tr>
                ))
              ) : contactRequest.length > 0 ? (
                contactRequest.map((ele, index) => (
                  <tr key={ele._id}>
                    <td>{index + 1}.</td>
                    <td>
                      <div>
                        <strong>Request Id: </strong>
                        {ele?._id}
                      </div>
                      <div>
                        <strong>Subject: </strong>
                        {ele?.subject}
                      </div>

                      <div>
                        <strong>Message: </strong>
                        {ele?.message?.length > 40
                          ? ele?.message.slice(0, 40) + "..."
                          : ele?.message}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong>User id: </strong>
                        {ele?.userId}
                      </div>
                      <div>
                        <strong>User Name: </strong>
                        {ele?.username}
                      </div>
                      <div>
                        <strong>User Mobile: </strong>
                        {ele?.mobile}
                      </div>
                      <div>
                        <strong>Email: </strong>
                        {ele?.email}
                      </div>
                    </td>
                    <td>
                      {new Date(ele.createdAt).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </td>

                    <td>
                      {/* <Button
                        onClick={() => {
                          navigate(`${ele?.id}`);
                        }}
                      >
                        View Details
                      </Button> */}

                      <button
                        onClick={() => {
                          navigate(`${ele?._id}`);
                        }}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No Contact Requests Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
