import React, { useEffect, useState } from "react";
import { getallAboutUsOverView, getallAwards } from "../api/AboutUsApi";
import Table from "react-bootstrap/Table";
import { Toaster } from "react-hot-toast";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url, cdn_url } from "../constant/Url";

export const AboutUsAwards = () => {
  const [AwardsData, setAwardsData] = useState([]);

  const FetchAllAwards = async () => {
    try {
      const res = await getallAwards();
      console.log(res);
      if (res?.status === "success") {
        setAwardsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    FetchAllAwards();
  }, []);

  const handleSingleCategory = async () => {};
  const handleUpdateCategory = async () => {};
  const handleDeleteCategory = async () => {};
  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="row">
        <div className="d-flex justify-content-between">
          <div>
            <span style={{ fontWeight: "600", fontSize: "20px" }}>
              Awards Data Management
            </span>
          </div>
        </div>
      </div>

      <div className="row py-4 table_user_data" style={{ overflow: "auto" }}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Award Title</th>
              <th>Award Year</th>
              <th>Award Image</th>
              <th>Award Organization</th>
              <th>actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(AwardsData) && AwardsData.length > 0 ? (
              AwardsData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
                .map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.AwardTitle}</td>
                    <td>{ele?.AwardYear}</td>
                    <td>
                      <img
                        src={`${cdn_url}${ele?.AwardImage}`}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>
                      {ele?.AwardOrganization?.length > 10
                        ? ele?.AwardOrganization?.slice(0, 10) + "..."
                        : ele?.AwardOrganization}
                    </td>
                    <td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => handleSingleCategory(ele)}
                            className="action_button_view"
                          >
                            <IoEye />
                          </div>
                          <div
                            className="px-2 action_button_update"
                            onClick={() => handleUpdateCategory(ele)}
                          >
                            <HiPencilSquare className="text-success" />
                          </div>
                          <div
                            className="action_button_delete"
                            onClick={() => handleDeleteCategory(ele)}
                          >
                            <IoTrashBinOutline className="text-danger" />
                          </div>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
