import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Dropdown, Modal, Form } from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import { Base_Url } from "../constant/Url";
import { Field } from "formik";
import { getDecryptedCookie, getStatusDescription, isAuthenticated } from "../utils/Utils";
import { refundApi } from "../api/refundApi";

export const OrderMgmt = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  // refund 
  const [noOrdersMessage, setNoOrdersMessage] = useState("");


  const [filterType, setFilterType] = useState("recent");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  



  const [paymentId, setPaymentId] = useState("");
  console.log(paymentId,'paymentIdpaymentId')
  const [amount, setAmount] = useState("");
  const [orderCreated, setOrderCreated] = useState(false);
  const [query, setQuery] = useState("");




// Function to create an order



const createOrderOnShipRocket = async (data) => {
  console.log(data, "importantDataFor Order creation");
  try {
    const res = await axios.post(
      `${Base_Url}/order/admin/createOrderOnShipRocket`,
      {
        orderId: data?._id,
        userId: data?.userId,
        shippingAddress: data?.shippingAddress,
      }
    );

    console.log("Order Created Successfully:", res.data);

    if (res?.data.success === true) {
      alert("Order Created Successfully!");
      setOrderCreated(true); // Hide button after success
    }
  } catch (error) {
    console.error("Error Creating Order:", error);
    alert("Failed to create order. Please try again.");
  }
};


function handleCreateReturnOrder(){
  
}




  // refund 
  const getInvoiceByOrderId = async (orderId) => {
    if (!orderId) {
      console.error("Order ID is required");
      return;
    }
  
    try {
      const response = await axios.get(`${Base_Url}/invoice/getInvoiceByOrderId/${orderId}`);
      
      if (response?.data?.status === "success" && response?.data?.data?.pdfUrl) {
        const pdfUrl = `${Base_Url}${response.data.data.pdfUrl}`;
  
        // Open the invoice in a new tab
        window.open(pdfUrl, "_blank");
      } else {
        console.error("Error: PDF URL not found or invoice is not created");
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };
  // Fetch orders from the backend
  const fetchOrders = async () => {
    setLoading(true);
  
    // Prepare the URL based on the filter type and custom date range (if applicable)
    let url = `${Base_Url}/order/filter-order?filterType=${filterType}`;
  
    // If 'dateRange' is selected, append startDate and endDate to the URL
    if (filterType === "dateRange" && startDate && endDate) {
      url = `${url}&startDate=${startDate}&endDate=${endDate}`;
    }
  
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${isAuthenticated}`,
          "Content-Type": "application/json",
        },
      });
  
      // Check if no orders were returned and handle the response accordingly
      if (response.data.status === "failed" && response.data.message === "No orders found for the given filter.") {
        setOrders([]);  // Clear any previous orders (or set an empty array)
        setNoOrdersMessage("No orders found for the given filter. Please try adjusting your filter criteria.");
      } else {
        setOrders(response.data.data || []); // Set orders if they exist
        setNoOrdersMessage(""); // Clear the "No orders" message if data is found
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      setOrders([]);
      setNoOrdersMessage("There was an error fetching the orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  
  

  // Update order status
  const updateOrderStatus = async (orderId, statusType, value) => {
    try {
      const response = await axios.put(
        `${Base_Url}/order/admin/order/${orderId}`,
        { [statusType]: value }  // Sending the dynamic status (isPaid or isDelivered)
      );
      alert(response.data.message || "Order updated successfully");
      fetchOrders(); // Refresh the order list after the update
    } catch (error) {
      console.error("Error updating order:", error);
      alert("Failed to update order status");
    }
  };
  const handleCancelOrder = async (orderId) => {
    try {
       const AdminData = getDecryptedCookie("AdminData"); // Get the decrypted user data from cookies
      
        const token = AdminData?.token;
      const response = await axios.post(
        `${Base_Url}/order/deleteOrder`,{orderId:orderId},{
          headers: {
            Authorization: `Bearer ${token}`, 
            "Content-Type": "application/json",
          },
        }
      );
      alert(response.data.message || "Order updated successfully");
      fetchOrders(); // Refresh order list after update
    } catch (error) {
      console.error("Error updating order:", error);
      alert("Failed to update order status");
    }
  };
  // Delete order
  const deleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(
        `${Base_Url}/order/admin/order/${orderId}`
      );

      if (response?.data?.status == "success") {
        toast.success("Order Deleted Successfully", { duration: 3000 });
        fetchOrders();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  useEffect(() => {
    fetchOrders(); // Fetch orders whenever the filter type changes
  }, [filterType, startDate, endDate]);

  // Refund Modal Functions

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [BookOrderMethods, setBookOrderMethods] = useState("");

  const handleRefundModalClose = () => setShowRefundModal(false);
  function handleRefundModalShow(id){
    setShowRefundModal(true);
    setPaymentId(id)
  }

  const handleRefundClick = async () => {
    if (!paymentId || !amount) {
      alert("Please enter Payment ID and Amount.");
      return;
    }
    try {
      await refundApi(amount, paymentId);
      alert("Refund processed successfully!");
      handleRefundModalClose()
    } catch (error) {
      alert("Refund failed. Please try again.");
      handleRefundModalClose()
    }
  };
  const handleBookOrderMethodsChange = async (orderId, value) => {
    try {
      const response = await axios.put(
        `${Base_Url}/order/admin/updateShippingmethod/${orderId}`,
        { orderShippingMethod: value }
      );
  
      // Show a success toast
      toast.success("Order updated successfully!");
  
      fetchOrders(); // Refresh the order list after update
    } catch (error) {
      console.error("Error updating order:", error);
      
      // Show an error toast
      toast.error("Failed to update order status");
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
console.log(searchQuery,'searchQuerysearchQuery')
  // Search orders
  const handleSearch = async () => {
    // Check if the search query is empty before proceeding
    if (!searchQuery) return;
  
    // Set loading state to true to show loading indicator
    setLoading(true);
  
    try {
      // Make GET request with query parameter correctly formatted in URL
      const response = await axios.get(
        `${Base_Url}/order/filter-order`, // Your API endpoint (no need to add `orderId` in URL)
        {
          params: {
            orderId: searchQuery, // Send searchQuery as query parameter in the request
          },
          headers: {
            Authorization: `Bearer ${isAuthenticated}`, // Pass the token for authentication
            "Content-Type": "application/json", // Ensure correct content type
          },
        }
      );
  
      // Check if the response contains data
      if (response?.data?.status === "success") {
        setOrders(response.data.data || []); // Set orders if data is found
      } else {
        setOrders([]); // Clear orders if no data is found
        toast.info("No matching orders found."); // Inform user that no results were found
      }
    } catch (error) {
      // Handle different types of errors
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error response from server:", error.response);
        toast.error(`Error: ${error.response.data?.message || "Something went wrong."}`);
      } else if (error.request) {
        // No response from server (network error)
        console.error("No response from server:", error.request);
        toast.error("Network error. Please check your connection and try again.");
      } else {
        // Something went wrong in setting up the request
        console.error("Error in setting up request:", error.message);
        toast.error(`Error: ${error.message}`);
      }
    } finally {
      // Set loading to false after the request is completed
      setLoading(false);
    }
  };
  
  
  // Handle the input change
  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchQuery(value);  // Update the search query state
  };

  return (
    <div className="container">
        <div className="row">
          {/* col start */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      {/* title div start */}  
      <div className="title_search_div">
      <h4>Search Orders</h4>
      </div>
      {/* title div end */}
      {/* search form start */}
      <div className="search_form_div">
      <div className="input-group">
        <Form.Control
          type="text"
          placeholder="Search by Order ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control"
        />
        <Button variant="primary" className="btn btn-md" onClick={handleSearch}>
          Search
        </Button>
      </div>
      </div>
      {/* search form end */}



      <Toaster />
        {/* admin order mgmt title div start */}
        <div className="admin_order_mgt_title_div">
        {/* <h2>Admin Order Management</h2> */}
        <div className="d-flex align-items-center justify-content-between">
        <h2>{`${orders?.length} orders found`}</h2>
        <button className="btn btn-danger" onClick={()=>window.location.reload()}>Reset</button>
        </div>
        </div>
        {/* admin order mgmt title div end */}
        
          {/*===== navtabs div start main ===== */}
    <div className="navtabs_main_div">
    {/* navs div start */}
  <nav>
    <div className="nav nav-tabs" id="nav-tab" role="tablist">

      <button className="nav-link active" id="nav-Orders-tab" data-bs-toggle="tab" data-bs-target="#nav-Orders" type="button" role="tab" aria-controls="nav-Orders" aria-selected="true">Orders</button>

      <button className="nav-link" id="nav-OrderStatus-tab" data-bs-toggle="tab" data-bs-target="#nav-OrderStatus" type="button" role="tab" aria-controls="nav-OrderStatus" aria-selected="false">Order Status</button>
      
    </div>
  </nav>
  
    {/* navs div end */}

{/* navs tab content div start */}
  <div className="tab-content" id="nav-tabContent">

    {/* navs tab pane div start */}
    <div className="tab-pane fade show active" id="nav-Orders" role="tabpanel" aria-labelledby="nav-Orders-tab">
    {loading ? (
  <p>Loading orders...</p>
) : (
  <>
  {/* orders table start */}
  <div className="orders_table_div"> 

  <div>
     

      {/* Filter Controls */}
      <div className="admin_filter_ordermngt">
        <label>
          Filter By:
          <select
            onChange={(e) => setFilterType(e.target.value)}
            value={filterType}
          >
            <option value="recent">Recent Orders</option>
            <option value="today">Orders Today</option>
            <option value="yesterday">Orders yesterday</option>
            <option value="dateRange">Custom Date Range</option>
          </select>
        </label>
      </div>

      {/* Custom Date Range Inputs */}
      {filterType === "dateRange" && (
        <div>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>
      )}

      {loading ? <p>Loading orders...</p> : null}

    
    </div>
  <table className="table bordered">
  

    <thead>
      <tr>
        <th>#</th>
        <th>User</th>
        <th>Order Details</th>
        <th>Payment Mode</th>

       
        <th>Booking Method</th>
       
        <th>Create Shipping Order</th>
       
        
      </tr>
    </thead>

    <tbody>
      {Array.isArray(orders) && orders?.length > 0 ? (
        orders?.map((order, index) => (
          <tr key={order._id} style={{ fontSize: "12px" }}>
            <td>{index + 1}</td>
            {console.log(order?.ShipRocket?.status_code,'orderorderorderorderorder')}
            <td>
              <div> <b>User Name:</b> {order?.userDetails?.name}</div>
              <div> <b>User Mobile:</b> {order?.userDetails?.mobile}</div>
              <div> <b>User Email:</b> {order?.userDetails?.email}</div>
              {/* <div> <b>User Id:</b> {order?.userId}</div> */}
            </td>

            <td>
              <div>
                <strong>Order Id</strong> - {order?._id || "N/A"}
              </div>
              {order?.paymentDetails?.method=="Razorpay" && <div>
                <strong>paymentId Id</strong> - {order?.paymentDetails?.razorpay_payment_id || "N/A"}
              </div>}
              <ul className="m-0 ps-2">
                {order?.orderItems?.map((item) => (
                  <li key={item._id} className="my-3">
                    <div>
                      <strong>Product Name:</strong> {item?.productName}
                    </div>

                    
                   <> {typeof item?.productId !='object' &&<div>
                      <strong>Product ID:</strong> {item?.productId}
                    </div>}
                    </>


                    <div>
                      <strong>Unit Price:</strong> {item?.discountPrice}{" "}
                      INR.
                    </div>
                    <div>
                      <strong>Quantity:</strong> {item?.quantity}
                    </div>
                  </li>
                ))}
              </ul>

              <div>
                <strong>Total Price</strong> - ₹ {order.totalPrice}
                {/* {parseInt(order.totalPrice.toFixed(2)).toLocaleString()}{" "} */}
                /-
              </div>
              <div>
                <strong>Order Date</strong> -{" "}
                <span className="pe-2">
                  {new Date(order.createdAt).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
                <span>
                  {new Date(order.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              </div>

              {/* <div>
                Total quantity -
                {order?.orderItems?.reduce(
                  (total, item) => total + item.quantity,
                  0
                )}{" "}
                Nos.
              </div> */}

              <div>
                <div><strong>Delivery Address:</strong></div>
                <div>
                  {order?.shippingAddress?.fullName},
                  {order?.shippingAddress?.addressLine1},
                  {order?.shippingAddress?.city},
                  {order?.shippingAddress?.state},
                  {order?.shippingAddress?.country},
                  {order?.shippingAddress?.state},
                  {order?.shippingAddress?.postalCode}
                </div>
              </div>
            </td>

            <td><b>{order?.paymentDetails?.method=="Razorpay"?"online Payment":"Cod"}</b></td>

         

       <td>
  <select
    className="custom-dropdown"
    value={order?.orderShippingMethod}
    onChange={(event) => handleBookOrderMethodsChange(order._id, event.target.value)}
  >
    <option value="" >Select Status</option>
    <option value="shiprocket">Ship Rocket</option>
    <option value="speedpost">Speed Post</option>
    <option value="manual">Manual</option>
  </select>
</td>

          
          {order?.data?.ShipRocket?.status_code!='undefined'?<>  {order?.orderShippingMethod != "manual" && order?.orderShippingMethod != undefined 
            && order?.isCancelled == false ? (
<td onClick={()=>createOrderOnShipRocket(order)}>
<Button variant="secondary" size="sm">
Create Order by {order?.orderShippingMethod}
</Button>
</td>
) : (
<div>---</div>
)}</> :"Awb initialized"}





           
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="12" className="text-center">
            Data Not Available
          </td>
        </tr>
      )}
    </tbody>
  </table>
  
  </div>
  {/* orders table end */}
  </>
)}
    </div>
    {/* navs tab pane div end */}

    {/* navs tab pane div start */}
    <div className="tab-pane fade" id="nav-OrderStatus" role="tabpanel" aria-labelledby="nav-OrderStatus-tab">
    {loading ? (
  <p>Loading orders...</p>
) : (
  <>
  {/* orders table start */}
  <div className="orders_table_div"> 
  <table className="table bordered">
  

    <thead>
      <tr>
        <th>#</th>
        <th>Payment Status</th>
        <th>Delivery Status</th>
        <th>Cancel Status</th>
        <th>Invoice</th>
        <th>Create a Return Order</th>
        <th>Refund</th>
        
        
      </tr>
    </thead>

    <tbody>
      {Array.isArray(orders) && orders?.length > 0 ? (
        orders?.map((order, index) => (
          <tr key={order._id} style={{ fontSize: "12px" }}>
            <td>{index + 1}</td>
            
           
            <td className={order.isPaid ? "paid_btn_td" : "notpaid_btn_td"}>
  <Dropdown>
    <Dropdown.Toggle>
      {order.isPaid ? "Paid" : "Not Paid"}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {/* Mark as Paid */}
      <Dropdown.Item
        onClick={() => updateOrderStatus(order._id, 'isPaid', true)} // Mark as Paid
      >
        Mark as Paid
      </Dropdown.Item>
      {/* Mark as Not Paid */}
      {/* <Dropdown.Item
        onClick={() => updateOrderStatus(order._id, 'isPaid', false)} // Mark as Not Paid
      >
        Mark as Not Paid
      </Dropdown.Item> */}
      {/* Optionally, you can add a separate dropdown item for "Delivered" status, if needed */}
    </Dropdown.Menu>
  </Dropdown>
</td>
            <td  className="invoice_btn">
             {getStatusDescription(order?.shipmentTrackingDetails?.tracking_data?.shipment_status)}

              {/* <Dropdown>
                <Dropdown.Toggle>
                  {order?.isDelivered ? "Delivered" : "Not Delivered"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      updateOrderStatus(order?._id, "isDelivered", true)
                    }
                  >
                    Mark as Delivered
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      updateOrderStatus(order?._id, "isDelivered", false)
                    }
                  >
                    Mark as Not Delivered
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </td>

            <td className={order?.isCancelled ? "cancelorder_btn" : "notcancelorder_btn"} >
<Dropdown>
<Dropdown.Toggle>
{order?.isCancelled ? "Canceled Order" : "Select Cancel/Canceled Order"}
</Dropdown.Toggle>
<Dropdown.Menu>
{!order?.isCancelled && (
  <Dropdown.Item onClick={() => handleCancelOrder(order?._id)}>
    Cancel Order
  </Dropdown.Item>
)}
{order?.isCancelled && (
  <Dropdown.Item disabled>
    Canceled Order
  </Dropdown.Item>
)}
</Dropdown.Menu>
</Dropdown>
</td>

<td onClick={()=>getInvoiceByOrderId(order?._id)}>
              <button className="invoice_btn">
                Invoice
              </button>
            </td>

          {getStatusDescription(order?.shipmentTrackingDetails?.tracking_data?.shipment_status) === "Delivered" ? 
  <td className="refund_td_btn" disabled onClick={() => handleCreateReturnOrder(order?._id)}>
    <b>Create Order</b>
  </td>
  : 
  <td className="refund_cod_btn">
    <b>Create Order</b>
  </td>
}

{ order?.paymentDetails?.method=="Razorpay" ? 
          <td className="refund_td_btn"
          onClick={()=>handleRefundModalShow(order?.paymentDetails?.razorpay_payment_id)}> 
          <b>Refund</b> </td>
          :<td className="refund_cod_btn"><b>Refund (COD)</b></td>}

          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="12" className="text-center">
            Data Not Available
          </td>
        </tr>
      )}
    </tbody>
  </table>
  
  </div>
  {/* orders table end */}
  </>
)}
    </div>
    {/* navs tab pane div end */}
    
  </div>
{/* navs tab content div end */}
</div>
{/*===== navtabs div start end ===== */}



      {/* Refund Modal */}
      <div>
        <div className="row">
          <Modal
            show={showRefundModal}
            onHide={handleRefundModalClose}
            backdrop="static"
            keyboard={false}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Refund Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
        <Form className="refund_from_div">
          <Form.Group>
            <Form.Label>Payment ID</Form.Label>
            <Form.Control
              type="text"
              
              placeholder="Enter Payment ID"
              value={paymentId}
              onChange={(e) => setPaymentId(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Refund Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="footer_modal">
        <Button variant="secondary" onClick={handleRefundModalClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleRefundClick}>
          Process Refund
        </Button>
      </Modal.Footer>
          </Modal>
        </div>
      </div>
      
      </div>
          {/* col end */}
    </div>
    </div>
  );
};
