import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../styles/Testimonial.css";
import Table from "react-bootstrap/Table";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { IoEye, IoStar, IoStarOutline } from "react-icons/io5";
import { formatDistanceToNow } from "date-fns";
import StarRating from "./StarRating";
import { GrFormAdd } from "react-icons/gr";
import { Toaster, toast } from "react-hot-toast";
import {
  createTestimonialData,
  DeleteSingleTestimonialsData,
  getallTestimonialData,
  GetSingleTestimonialsData,
  updateSingleTestimonialsData,
} from "../api/TestimonialApi";
import { Base_Url, cdn_url } from "../constant/Url";
import { getDecryptedCookie } from "../utils/Utils";

const TestimonialSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required"),
  Image: Yup.string().required("Image URL is required"),
  Rating: Yup.number()
    .required("Rating is required")
    .min(1, "Minimum rating is 1")
    .max(5, "Maximum rating is 5"),
  Description: Yup.string().required("Description is required"),
  Status: Yup.string().required("Status is required"),
});

export const Testimonials = () => {
  const AdminData = getDecryptedCookie("AdminData");
  const token = AdminData?.token;

  const [showTestimonialModal, setShowTestimonialModal] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);

  const handleTestimonialModalClose = () => setShowTestimonialModal(false);
  const handleTestimonialModalShow = () => setShowTestimonialModal(true);

  const [showSingleTestimonialModal, setShowSingleTestimonialModal] =
    useState(false);

  const handleSingleTestimonialModalClose = () =>
    setShowSingleTestimonialModal(false);

  const [showUpdateTestimonialModal, setShowUpdateTestimonialModal] =
    useState(false);

  const handleUpdateTestimonialModalClose = () =>
    setShowUpdateTestimonialModal(false);
  const handleUpdateTestimonialModalShow = () =>
    setShowUpdateTestimonialModal(true);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Image", values.Image);
    formData.append("Rating", values.Rating);
    formData.append("Description", values.Description);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createTestimonialData(formData);
      console.log(res);

      if (res?.data?.status === "success") {
        setTestimonialsData([...testimonialsData, res.data.testimonial]);
        toast.success("Testimonial Added Successfully!");
        handleTestimonialModalClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating testimonial:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const FetchAllTestimonials = async () => {
    try {
      const res = await getallTestimonialData(token);

      if (res?.status === "success") {
        setTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllTestimonials();
  }, []);

  const [singletestimonials, setSingletestimonials] = useState();

  const handleSingleTestimonial = async (ele) => {
    console.log(ele);
    try {
      setShowSingleTestimonialModal(true);
      const res = await GetSingleTestimonialsData(ele?._id);
      console.log(res);
      if (res?.status === "success") {
        setShowTestimonialModal(false);
        setSingletestimonials(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [updateTestimonialData, setUpdateTestimonialData] = useState(null);

  const handleUpdateTestimonial = async (ele) => {
    console.log(ele);
    try {
      const res = await GetSingleTestimonialsData(ele?._id);
      if (res?.status === "success") {
        setUpdateTestimonialData(res.data); // Store the fetched data in state
        handleUpdateTestimonialModalShow();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitUpdate = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Name", values.Name);
    formData.append("Image", values.Image);
    formData.append("Rating", values.Rating);
    formData.append("Status", values.Status);
    formData.append("Description", values.Description);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await updateSingleTestimonialsData(
        updateTestimonialData._id,
        formData,
        token
      );
      if (res?.data?.status === "success") {
        toast.success(res?.data?.message,{autoClose:"3000"});
        handleUpdateTestimonialModalClose();
        FetchAllTestimonials()
      }
    } catch (error) {
      console.error("Error updating testimonial:", error);
      toast.error("Oops! Some error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteTestimonial = async (ele) => {
    try {
      const res = await DeleteSingleTestimonialsData(ele?._id);
      if (res?.status === "success") {
        setTestimonialsData(
          testimonialsData.filter((item) => item._id !== ele._id)
        );
        toast.success("Testimonial Deleted Successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rating ? (
          <IoStar key={i} color="orange" />
        ) : (
          <IoStarOutline key={i} />
        )
      );
    }
    return stars;
  };

  const timeSinceCreation = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Return a fallback value
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <div className="justify-content-between">
          <div className="category_managment_title_div">
                       <h1>Testimonials</h1>
                       <button className="addbutton" onClick={handleTestimonialModalShow}>
              Add Testimonials{" "}
              <GrFormAdd style={{ fontWeight: "800", fontSize: "25px" }} />
            </button>
                      </div>
          
          <div>
            
          </div>
        </div>
      </div>

      <div className="row">
        <div className="testimonials_table">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Name</th>
              <th>Image</th>
              <th>Description</th>
              <th>Rating</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(testimonialsData) && testimonialsData?.length > 0 ? (
              testimonialsData.map((ele, index) => (
                <tr key={ele?._id}>
                  <td>{index + 1}</td>
                  <td>{ele?.Name}</td>
                  <td>
                    <img
                      src={`${cdn_url}${ele?.Image}`}
                      alt={ele?.Name}
                      style={{ width: "50px", height: "50px" }}
                    />
                  </td>
                  <td>
                    {ele?.Description?.length > 40
                      ? ele?.Description?.slice(0, 60) + "..."
                      : ele?.Description}
                  </td>
                  <td>{renderStars(ele?.Rating)}</td>
                  <td><b className={`status-text ${ele?.Status}`}>{ele?.Status}</b></td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div
                        onClick={() => handleSingleTestimonial(ele)}
                        className="action_button_view"
                      >
                        <IoEye />
                      </div>
                      <div
                        className="px-2 action_button_update"
                        onClick={() => handleUpdateTestimonial(ele)}
                      >
                        <HiPencilSquare className="text-success" />
                      </div>
                      <div
                        className="action_button_delete"
                        onClick={() => handleDeleteTestimonial(ele)}
                      >
                        <IoTrashBinOutline className="text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>

      {/* Add Testimonial Modal */}

      <div className="row">
        <Modal
          show={showTestimonialModal}
          onHide={handleTestimonialModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                Name: "",
                Image: null,
                Rating: "",
                Description: "",
              }}
              validationSchema={TestimonialSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, isSubmitting }) => (
                 <div className="update_tesimonials_form_div">
                <Form>
                  <div>
                    <label>User Name</label>
                    <Field type="text" name="Name" className="form-control Form_Field" />
                    <ErrorMessage
                      name="Name"
                      component="div"
                      className="errormsg text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <label>Image</label>
                    <input
                      type="file"
                      name="Image"
                      className="form-control Form_Field"
                      onChange={(event) => {
                        setFieldValue("Image", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="Image"
                      component="div"
                      className="errormsg text-danger"
                    />
                  </div>

                  <div className="mt-2">
                    <label>Rating</label>
                    <Field name="Rating">
                      {({ field }) => (
                        <StarRating
                          name="Rating"
                          value={values.Rating}
                          onChange={(rating) => setFieldValue("Rating", rating)}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="Rating"
                      component="div"
                      className="errormsg text-danger"
                    />
                  </div>

                  <div>
                    <label>Description</label>
                    <textarea
                      type="textarea"
                      name="Description"
                      className="form-control Form_Field"
                      rows={5}
                    />
                    <ErrorMessage
                      name="Description"
                      component="div"
                      className="errormsg text-danger"
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleTestimonialModalClose}
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
                </div>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>

      {/* View Single Testimonial Modal */}

      <div className="row">
        <Modal
          show={showSingleTestimonialModal}
          onHide={handleSingleTestimonialModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="testimonials_user_review_txt">
              <div className="Testimonial_ImageDiv">
                <img
                  src={`${cdn_url}${singletestimonials?.Image}`}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="ps-2" style={{ fontWeight: "600" }}>
                {singletestimonials?.Name}
              </div>
            </div>

            <div className="d-flex align-items-center gap-1 justify-content-center pt-1">
              <div className="testimonilas_review_div">{renderStars(singletestimonials?.Rating)}</div>
              <div className="title_testimonial_modal">
                (Updated ~ {timeSinceCreation(singletestimonials?.createdAt)})
              </div>
            </div>

            <div className="testimonial_txt_div">{singletestimonials?.Description}</div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Update Single Testimonial Modal */}

      <div className="row">
        <Modal
          show={showUpdateTestimonialModal}
          onHide={handleUpdateTestimonialModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {updateTestimonialData && (
              <Formik
                initialValues={{
                  Name: updateTestimonialData.Name || "",
                  Image: updateTestimonialData.Image || null,
                  Rating: updateTestimonialData.Rating || "",
                  Description: updateTestimonialData.Description || "",
                  Status: updateTestimonialData.Status || "",
                }}
                validationSchema={TestimonialSchema}
                onSubmit={handleSubmitUpdate} // Create a separate function for handling updates
              >
                {({ setFieldValue, values, isSubmitting }) => (
                  <div className="update_tesimonials_form_div">
                  <Form>
                    <div>
                      <label>User Name</label>
                      <Field type="text" name="Name" className="form-control Form_Field" />
                      <ErrorMessage
                        name="Name"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <label>Image</label>
                      <input
                        type="file"
                        name="Image"
                        className="form-control Form_Field"
                        onChange={(event) => {
                          setFieldValue("Image", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="Image"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div className="rating_testimonial">
                      <label>Rating</label>
                      <Field name="Rating">
                        {({ field }) => (
                          <StarRating
                            name="Rating"
                            value={values.Rating}
                            onChange={(rating) =>
                              setFieldValue("Rating", rating)
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="Rating"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div>
                      <label>Description</label>
                      <textarea
                        type="textarea"
                        name="Description"
                        className="form-control Form_Field"
                        rows={5}
                      />
                      <ErrorMessage
                        name="Description"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <div className="mt-2">
                      <label>Status</label>
                      <Field as="select" name="Status" className="testimonial_select Form_Field">
                        <option value="">Select Status</option>
                        <option value="approve">Approve</option>
                        <option value="pending">Pending</option>
                        <option value="rejected">Rejected</option>
                      </Field>
                      <ErrorMessage
                        name="Status"
                        component="div"
                        className="errormsg text-danger"
                      />
                    </div>

                    <Modal.Footer>
                      <button
                        className="close_btn_up"
                        onClick={handleUpdateTestimonialModalClose}
                        disabled={isSubmitting}
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="update_btn_up"
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                    </Modal.Footer>
                  </Form>
                  </div>
                )}
              </Formik>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
