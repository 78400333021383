

import axios from "axios";
import { Base_Url } from "../constant/Url";

export const getallTestimonialData = async (token) => {
  try {
    const res = await fetch(`${Base_Url}/testimonials/getalltestimonials`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const createTestimonialData = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/testimonials/createtestimonial`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating testimonial:", error);
    throw error;
  }
};

export const GetSingleTestimonialsData = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/testimonials/getsingletestimonial/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleTestimonialsData = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/testimonials/deletetestimonials/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateSingleTestimonialsData = async (
  updateTestimonialData_id,
  formData,
  token
) => {
  try {
    const response = await axios.put(
      `${Base_Url}/testimonials/updatetestimonials/${updateTestimonialData_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};
