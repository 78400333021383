import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetUserProfile } from "../api/UserDataApi";
import { getDecryptedCookie } from "../utils/Utils";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Base_Url } from "../constant/Url";
import "../styles/Userprofile.css";

export const SingleUser = () => {
  const { userId } = useParams();
  const adminData = getDecryptedCookie("AdminData");
  const token = adminData?.token;

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const FetchSingleUserDetails = async () => {
    try {
      // Await the response
      const res = await GetUserProfile(userId, token);
      if (res?.status === "success") {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    FetchSingleUserDetails();
  }, []); // No need to add dependencies as `userId` and `token` won't change

  return (
    <div>
      <section className="user_profile_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              {/* userprofile main div start */}
              <div className="userprofile_main_div_relative">
                {/* userprofile flex div start */}
                <div className="userprofile_flex_main_div">
                  {/* userprofile image div start */}
                  <div className="userprofile_image_main_div">
                    {/* user image box start */}

                    {loading ? (
                      <div className="user_image_box">
                        <img
                          src="https://cdn.quality.healfate.in/astro_docs/2411081207444482No-image-found.png"
                          alt="User Profile"
                          className="img-fluid"
                        />

                        <Skeleton
                          height={50}
                          width={150}
                          style={{ margin: "10px auto", display: "block" }}
                        />

                        {/* userprofile edit btn div end */}
                      </div>
                    ) : (
                      <div className="user_image_box">
                        <img
                          // src={
                          //   userData?.ProfilePhoto?.trim()
                          //     ? `${Base_Url}${userData.ProfilePhoto}`
                          //     : "https://cdn.quality.healfate.in/astro_docs/2411081207444482No-image-found.png"
                          // }
                          src={`https://cdn.quality.healfate.in/astro_docs/2411081207444482No-image-found.png`}
                          alt="User Profile"
                          className="img-fluid"
                        />

                        <h2>
                          {loading ? <Skeleton width={10} /> : userData?.name}
                        </h2>

                        {/* userprofile edit btn div end */}
                      </div>
                    )}

                    {/* user image box end */}
                  </div>
                  {/* userprofile image div end */}

                  {/* user profile table start  */}
                  <div className="user_profile_details_flex_table">
                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Name:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>{userData?.name}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Email ID:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>{userData?.email}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Mobile No:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>+91-{userData?.mobile}</div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>Delivery Address:</h2>
                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>
                            {userData?.addresses?.length > 0 ? (
                              userData.addresses.map((address) =>
                                address._id === userData.primaryAddress ? (
                                  <div key={address._id}>
                                    <span>{address.addressLine1},</span>
                                    <span>
                                      {address.city}, {address.state},
                                      {address.country} - {address.postalCode}
                                    </span>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <p>No address available</p>
                            )}
                          </div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}

                    {/* user profile table row start */}
                    <div className="user_profile_table_row">
                      <h2>GST No:</h2>

                      <h3>
                        {loading ? (
                          <Skeleton height={20} width={150} />
                        ) : (
                          <div>
                            {userData?.gstnumber?.length > 0 ? (
                              <div>{userData.gstnumber.at(-1)}</div>
                            ) : (
                              <p>No GST available</p>
                            )}
                          </div>
                        )}
                      </h3>
                    </div>
                    {/* user profile table row end */}
                  </div>
                  {/* user profile table end */}
                </div>
                {/* userprofile flex div end */}
              </div>
              {/* userprofile main div end */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
    </div>
  );
};
