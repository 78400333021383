import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDecryptedCookie } from "../utils/Utils";
import { getSingleContactRequest } from "../api/ContactRequestApi";

export const ContactSingleRequest = () => {
  const { id } = useParams();

  const adminData = getDecryptedCookie("AdminData");
  const token = adminData?.token;

  const [loading, setLoading] = useState(true);
  const [singleContactRequest, setSingleContactRequest] = useState([]);

  const FetchSingleRequest = async () => {
    setLoading(true);
    try {
      const res = await getSingleContactRequest(id, token);
      console.log(res, "getSingleContactRequestgetSingleContactRequest");
      if (res?.status === "success") {
        setSingleContactRequest(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchSingleRequest();
  }, []);

  return (
    <div>
      <div className="container">
      <div className="contact_request_flex_div">
          <div className="contac_pg_title">
            <h2 className="text-center">Single Contact Request</h2>
          </div>
          
        </div>

        <div className="contact_rqst_page_table">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>User Information</th>
                <th>Request Details</th>
                <th>Created At</th>
              </tr>
            </thead>

            <tbody>
              <tr key={singleContactRequest._id}>
                <td>
                  <div>
                    <strong>Request Id: </strong>
                    {singleContactRequest?._id}
                  </div>
                  <div>
                    <strong>Subject: </strong>
                    {singleContactRequest?.subject}
                  </div>

                  <div>
                    <strong>Message: </strong>
                    {singleContactRequest?.message}
                  </div>
                </td>
                <td>
                  <div>
                    <strong>User id: </strong>
                    {singleContactRequest?.userId}
                  </div>
                  <div>
                    <strong>User Name: </strong>
                    {singleContactRequest?.username}
                  </div>
                  <div>
                    <strong>User Mobile: </strong>
                    {singleContactRequest?.mobile}
                  </div>
                  <div>
                    <strong>Email: </strong>
                    {singleContactRequest?.email}
                  </div>
                </td>
                <td>
                  {new Date(singleContactRequest.createdAt).toLocaleString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
