import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const SECRET_KEY = "";

export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    return encrypted;
  } catch (error) {
    return null;
    // eff
  }
};

export const setEncryptedCookie = (key, data) => {
  // huhuhuhu
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      Cookies.set(key, encryptedData, { expires: 7 }); // Cookie expires in 7 days
    } else {
    }
  } catch (error) {}
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    return null;
  }
};

export const getDecryptedCookie = (key) => {
  try {
    const encryptedData = Cookies.get(key);
    if (encryptedData) {
      return decryptData(encryptedData);
    }
    return null;
  } catch (error) {
    return null;
  }
};

const userData = getDecryptedCookie("AdminData");

export const isAuthenticated = userData?.token;

export const getStatusDescription = (statusCode) => {
  const statusCodeMapping = {
    1: "AWB Assigned",
    2: "Label Generated",
    3: "Pickup Scheduled/Generated",
    4: "Pickup Queued",
    5: "Manifest Generated",
    6: "Shipped",
    7: "Delivered",
    8: "Cancelled",
    9: "RTO Initiated",
    10: "RTO Delivered",
    11: "Pending",
    12: "Lost",
    13: "Pickup Error",
    14: "RTO Acknowledged",
    15: "Pickup Rescheduled",
    16: "Cancellation Requested",
    17: "Out For Delivery",
    18: "In Transit",
    19: "Out For Pickup",
    20: "Pickup Exception",
    21: "Undelivered",
    22: "Delayed",
    23: "Partial Delivered",
    24: "Destroyed",
    25: "Damaged",
    26: "Fulfilled",
    38: "Reached at Destination",
    39: "Misrouted",
    40: "RTO NDR",
    41: "RTO OFD",
    42: "Picked Up",
    43: "Self Fulfilled",
    44: "Disposed Off",
    45: "Cancelled Before Dispatched",
    46: "RTO In Transit",
    47: "QC Failed",
    48: "Reached Warehouse",
    49: "Custom Cleared",
    50: "In Flight",
    51: "Handover to Courier",
    52: "Shipment Booked",
    54: "In Transit Overseas",
    55: "Connection Aligned",
    56: "Reached Overseas Warehouse",
    57: "Custom Cleared Overseas",
    59: "Box Packing"
  };

  return statusCodeMapping[statusCode] || "Unknown Status"; // Returns the description or 'Unknown Status'
};

