import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleBlog, updateBlogs } from "../api/BlogsApi";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";

export const UpdateBlogs = () => {
  const { blogsId } = useParams();
  const [BlogsData, SetBlogsData] = useState(null);

  const getSingleBlogDetails = async () => {
    try {
      const res = await getSingleBlog(blogsId);
      if (res?.status === "success") {
        SetBlogsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleBlogDetails();
  }, []);

  const validationSchema = Yup.object({
    BlogsAuthor: Yup.string().required("Blogs Author Name is required"),
    BlogsImage: Yup.string().required("Blogs Image is required"),
    BlogsTitle: Yup.string().required("Blogs Title Name is required"),
    BlogsDescription: Yup.string().required("Blogs Descriptionis required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("BlogsAuthor", values.BlogsAuthor);
    formData.append("BlogsImage", values.BlogsImage);

    formData.append("BlogsTitle", values.BlogsTitle);
    formData.append("BlogsDescription", values.BlogsDescription);

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await updateBlogs(blogsId, formData);
      if (res?.data?.status === "success") {
        toast.success("Blogs Updated Successfully!", { duration: 3000 });
        resetForm();
        getSingleBlogDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>UpdateBlogs</div>

      <div className="row">
        <Toaster position="top-right" reverseOrder={false} />

        <Formik
          enableReinitialize
          initialValues={{
            BlogsAuthor: BlogsData?.BlogsAuthor || "",
            BlogsImage: "",

            BlogsTitle: BlogsData?.BlogsTitle || "",
            BlogsDescription: BlogsData?.BlogsDescription || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Blogs Author Name
                    <span>*</span>
                  </div>
                  <Field
                    type="text"
                    name="BlogsAuthor"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="BlogsAuthor"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Blogs Title
                    <span>*</span>
                  </div>
                  <Field
                    type="text"
                    name="BlogsTitle"
                    className="Form_Field_Input"
                  />
                  <ErrorMessage
                    name="BlogsTitle"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Blogs Image
                    <span>*</span>
                  </div>

                  <input
                    type="file"
                    name="BlogsImage"
                    onChange={(event) => {
                      setFieldValue("BlogsImage", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="BlogsImage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Blogs Description
                    <span>*</span>
                  </div>

                  <Field
                    as="textarea"
                    name="BlogsDescription"
                    placeholder="Add a comment..."
                    rows="3"
                    className="Form_Field_Input"
                    required
                  />

                  <ErrorMessage
                    name="BlogsDescription"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="Form_Field_Submit_Button">
                <button type="submit">Update Blog</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
