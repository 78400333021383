import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { Toaster, toast } from "react-hot-toast";

import { getAllOurBrands } from "../api/OurBrandsApi.js";
import {
  FetchAllSubCategoriesWithCategories,
  getallCategory,
} from "../api/CategoryApi.js";
import {
  createProduct,
  DeleteSingleProduct,
  getallProducts,
  searchProductByNameAndId,
} from "../api/ProductsApi.js";

import Table from "react-bootstrap/Table";
import { IoEye } from "react-icons/io5";
import { IoTrashBinOutline } from "react-icons/io5";
import { HiPencilSquare } from "react-icons/hi2";
import { Base_Url, cdn_url } from "../constant/Url.js";
import { Link, useNavigate } from "react-router-dom";
import { getAllDeals } from "../api/DealsApi.js";

export const Products = () => {
  const [ProductsData, setProductsData] = useState([]);
  const [AllShopsData, setAllShopsData] = useState([]);
  const [categoryListData, setcategoryListData] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SubCategoryListData, setSubCategoryListData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const FetchAllShops = async () => {
    try {
      const res = await getAllOurBrands();
      if (res?.status === "success") {
        setAllShopsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

 // Fetch all products initially
 const FetchAllProducts = async () => {
  try {
    const res = await getallProducts();
    if (res?.status === "success") {
      setProductsData(res?.data);
    }
  } catch (error) {
    console.log("Error fetching products:", error);
  }
};

// Function to search products based on the input value
const handleSearch = async (query) => {
  if (!query) {
    setProductsData([]); // If the input is empty, clear the results
    return;
  }

  setLoading(true); // Set loading state to true while searching

  try {
    // Call the search function with the query
    const response = await searchProductByNameAndId({ query: query });

    if (response && response.data.status === "success") {
      setProductsData(response.data.data); // Update the state with the fetched products
    } else {
      setProductsData([]); // If no products are found, set empty results
    }
  } catch (error) {
    console.error("Search failed:", error);
    setProductsData([]); // Set empty results on error
  } finally {
    setLoading(false); // Set loading state to false after the request is done
  }
};

// Handle input change for search
const handleInputChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query); // Update the search query state

  if (query) {
    handleSearch(query); // Trigger the search function
  } else {
    FetchAllProducts(); // If query is empty, fetch all products
  }
};

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleShopChange = (e, setFieldValue) => {
    const selectedShop = e.target.value;
    const selectedShopData = AllShopsData?.find(
      (data) => data.OurBrandsName === selectedShop
    );
    setFieldValue("ShopName", selectedShop);
    setFieldValue("ShopNameSlug", selectedShopData?.OurBrandsNameSlug || "");
  };

  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategory = e.target.value;
    const selectedCategoryData = categoryListData?.find(
      (data) => data.CategoryName === selectedCategory
    );
    setFieldValue("CategoryName", selectedCategory);
    setFieldValue(
      "CategoryNameSlug",
      selectedCategoryData?.CategoryNameSlug || ""
    );
    setSelectedCategory(selectedCategoryData?.CategoryNameSlug || "");
  };

  const handleSubCategoryChange = (e, setFieldValue) => {
    const selectedSubCategory = e.target.value;
    const selectedSubCategoryData = SubCategoryListData?.find(
      (data) => data.SubCategoryName === selectedSubCategory
    );
    setFieldValue("SubCategoryName", selectedSubCategory);
    setFieldValue(
      "SubCategoryNameSlug",
      selectedSubCategoryData?.SubCategoryNameSlug || ""
    );
  };

  const getSubCategoryName = async () => {
    const data = {
      CategoryNameSlug: SelectedCategory,
    };
    try {
      const response = await FetchAllSubCategoriesWithCategories(data);
      if (response.status === "success") {
        setSubCategoryListData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object({
    CategoryName: Yup.string().required("Category Name is required"),
    SubCategoryName: Yup.string().required("Sub Category Name is required"),
    ProductName: Yup.string().required("Product Name is required"),
    ProductNameSlug: Yup.string().required("Product Name Slug is required"),
   

    ProductPrice: Yup.number().positive("Must be a positive number"),
    discountPercentage: Yup.number()
      .min(0, "Cannot be less than 0")
      .max(100, "Cannot exceed 100"),
   
   
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);

    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);

    formData.append("ProductName", values.ProductName);
    formData.append("ProductNameSlug", values.ProductNameSlug);

    formData.append("ProductDescription", values.ProductDescription);

    formData.append("ShopName", values.ShopName);
    formData.append("ShopNameSlug", values.ShopNameSlug);

    formData.append("ProductPrice", Number(values.ProductPrice));
    formData.append("DiscountPercentage", values.DiscountPercentage);
    formData.append("ProductFaqs", JSON.stringify(values.ProductFaqs));

    values.ProductFeatures.forEach((feature) => {
      formData.append("ProductFeatures", feature);
    });

    Array.from(values.ProductImage || []).forEach((file) => {
      formData.append("ProductImage", file);
    });

    formData.append("dealsType", values.dealsType);

    // // Append objects (Dimensions, General, Warranty)
    if (values.Dimensions) {
      formData.append("Length", values.Dimensions.Length || ""); // Append Length
      formData.append("Breadth", values.Dimensions.Breadth || ""); // Append Breadth
      formData.append("Height", values.Dimensions.Height || ""); // Append Height
      formData.append("Weight", values.Dimensions.Weight || ""); // Append Weight
    }
    // formData.append("General", JSON.stringify(values.General));
    // formData.append("Warranty", JSON.stringify(values.Warranty));

   

    

   

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const res = await createProduct(formData);
      if (res?.data?.status === "success") {
        alert("Product Added Successfully! ✅");
        window.location.reload(); // Reload the page
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProducts = async (ele) => {
    // Show a confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
  
    if (confirmDelete) {
      try {
        const res = await DeleteSingleProduct(ele?._id);
        if (res?.status === "success") {
          toast.success("Product Deleted Successfully!");
          // Filter out the deleted product from the list
          setProductsData(ProductsData.filter((item) => item._id !== ele._id));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      // If Cancel is clicked, show a message or do nothing
      toast?.error("Product deletion cancelled.");
    }
  };

  const handleUpdateProducts = (data) => {
    navigate(`/products/update/${data?._id}`);
  };

  useEffect(() => {
    FetchAllShops();
    FetchAllCategoryList();
    FetchAllProducts();
  }, []);

  useEffect(() => {
    getSubCategoryName();
  }, [SelectedCategory]);

  // Deals

  const [DealsData, SetDealsData] = useState(null);

  const fetchAllDeals = async () => {
    try {
      const response = await getAllDeals();
      console.log(response, "responseresponse");
      if (response?.status === "success") {
        SetDealsData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDealChange = (e, setFieldValue) => {
    const selectedDealId = e.target.value; // Get the selected deal ID
    const selectedDeal = DealsData.find((deal) => deal._id === selectedDealId); // Find the deal object
    console.log(selectedDeal); // Logs the full deal object
    setFieldValue("dealsType", selectedDealId); // Update Formik's `dealsType` field with the selected deal ID
  };

  useEffect(() => {
    fetchAllDeals();
  }, []);

  return (
    <>

<div className="container">
     
      <div className="row">
        <Toaster position="top-right" reverseOrder={false} />

        <Formik
          initialValues={{
            CategoryName: "",
            CategoryNameSlug: "",
            SubCategoryName: "",
            SubCategoryNameSlug: "",
            ShopName: "",
            ShopNameSlug: "",
            ProductName: "",
            ProductNameSlug: "",
            ProductPrice: "",
            DiscountPercentage: "",
            ProductDescription: "",
            dealsType: "",
            ProductFeatures: [],
          
            Dimensions: {
              Length: "",
              Breadth: "",
              Height: "",
              Weight: "",
            },

            Warranty: {
              WarrantySummary: "",
              WarrantyServiceType: "",
              CoveredInWarranty: "",
              NotCoveredInWarranty: "",
              DomesticWarranty: "",
              InternationalWarranty: "",
            },
            ProductFaqs: [{ Question: "", Answer: "" }],
            ProductImage: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className="container">
              <div className="row">
                
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
                      <div className="Form_Field_Heading">
                        Product Name
                        <span>*</span>
                      </div>
                      <Field
                        type="text"
                        name="ProductName"
                        className="form-control Form_Field_Input"
                      />
                      <ErrorMessage
                        name="ProductName"
                        component="div"
                        className="text-danger errormsg"
                      />
                    </div>

                    <div className="col-md-6 mb-2">
                      <div className="Form_Field_Heading">
                        Product Name Slug
                        <span>*</span>
                      </div>
                      <Field
                        type="text"
                        name="ProductNameSlug"
                        className="form-control Form_Field_Input"
                        onChange={(e) => {
                          const slug = e.target.value
                            .toLowerCase()
                            .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                            .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                          setFieldValue("ProductNameSlug", slug);
                        }}
                      />
                      <ErrorMessage
                        name="ProductNameSlug"
                        component="div"
                        className="text-danger errormsg"
                      />
                    </div>
                  

                {/* Product Faq */}

                <div className="col-md-12 Form_Field mb-2">
                  <div className="Form_Field_Heading">
                    Product FAQs
                    <span>*</span>
                  </div>
                  <FieldArray
                    name="ProductFaqs"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.ProductFaqs?.map((faq, index) => (
                          <div key={index}>
                            <div>
                            
                              <Field
                                name={`ProductFaqs[${index}].Question`}
                                className="form-control Form_Field_Input Form_Field_Input_Array"
                                placeholder="Enter Question"
                                value={faq.Question || ""}
                                onChange={(e) =>
                                  setFieldValue(
                                    `ProductFaqs[${index}].Question`,
                                    e.target.value
                                  )
                                }
                              />
                              <ErrorMessage
                                name={`ProductFaqs[${index}].Question`}
                                component="div"
                                className="text-danger errormsg"
                              />
                            </div>
                            <div>
                           
                              <Field
                                name={`ProductFaqs[${index}].Answer`}
                                className="form-control Form_Field_Input Form_Field_Input_Array"
                                placeholder="Enter Answer"
                                value={faq.Answer || ""}
                                onChange={(e) =>
                                  setFieldValue(
                                    `ProductFaqs[${index}].Answer`,
                                    e.target.value
                                  )
                                }
                              />
                              <ErrorMessage
                                name={`ProductFaqs[${index}].Answer`}
                                component="div"
                                className="text-danger errormsg"
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} className="remove_btn">
                              Remove FAQ
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ Question: "", Answer: "" })
                          }
                          className="add_btn" >
                          Add FAQ
                        </button>
                      </div>
                    )}
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Category Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="CategoryName"
                    className="form-control Form_Field_Input"
                    onChange={(e) => {
                      handleCategoryChange(e, setFieldValue);
                      setFieldValue("CategoryName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Category Name</option>

                    {categoryListData?.map((category) => (
                      <option key={category._id} value={category.CategoryName}>
                        {category.CategoryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="CategoryName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Sub Category Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="SubCategoryName"
                    className="form-control Form_Field_Input"
                    onChange={(e) => {
                      handleSubCategoryChange(e, setFieldValue);
                      setFieldValue("SubCategoryName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Sub Category Name</option>

                    {SubCategoryListData?.map((subcategory) => (
                      <option
                        key={subcategory._id}
                        value={subcategory.SubCategoryName}
                      >
                        {subcategory.SubCategoryName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="SubCategoryName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Deal Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="dealsType"
                    className="form-control Form_Field_Input"
                    onChange={(e) => handleDealChange(e, setFieldValue)}
                  >
                    <option value="">Select a Deal</option>
                    {DealsData?.map((item) => {
                      return (
                        <option key={item?._id} value={item?.dealName}>
                          {item?.dealName}
                        </option>
                      );
                    })}
                  </Field>
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Shop Name
                    <span>*</span>
                  </div>

                  <Field
                    as="select"
                    name="ShopName"
                    className="form-control Form_Field_Input"
                    onChange={(e) => {
                      handleShopChange(e, setFieldValue);
                      setFieldValue("ShopName", e.target.value); // Update Formik value as well
                    }}
                  >
                    <option value="">Select a Shop</option>

                    {AllShopsData?.map((shop) => (
                      <option key={shop._id} value={shop.OurBrandsName}>
                        {shop.OurBrandsName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="ShopName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Images
                    <span>*</span>
                  </div>
                  <input
                    name="ProductImage"
                    type="file"
                    className="form-control "
                    multiple
                    onChange={(e) =>
                      setFieldValue(
                        "ProductImage",
                        Array.from(e.currentTarget.files)
                      )
                    }
                  />

                  <ErrorMessage
                    name="ProductImage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-md-6 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Price
                    <span>*</span>
                  </div>
                  <Field
                    type="number"
                    name="ProductPrice"
                    className="form-control Form_Field_Input"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 5);
                    }}
                  />
                  <ErrorMessage
                    name="ProductPrice"
                    component="div"
                    className="text-danger"
                  />
                </div>


                <div className="col-md-6 Form_Field">
  <div className="Form_Field_Heading">
    Length
    <span>*</span>
  </div>
  <Field
    type="text"
    name="Dimensions.Length"
    className="form-control Form_Field_Input"
    onInput={(e) => {
      e.target.value = e.target.value;
    }}
  />
  <ErrorMessage name="Dimensions.Length" component="div" className="text-danger" />
</div>

<div className="col-md-6 Form_Field">
  <div className="Form_Field_Heading">
    Breadth
    <span>*</span>
  </div>
  <Field
    type="text"
    name="Dimensions.Breadth"
    className="form-control Form_Field_Input"
    onInput={(e) => {
      e.target.value = e.target.value;
    }}
  />
  <ErrorMessage name="Dimensions.Breadth" component="div" className="text-danger" />
</div>

<div className="col-md-6 Form_Field">
  <div className="Form_Field_Heading">
    Height
    <span>*</span>
  </div>
  <Field
    type="text"
    name="Dimensions.Height"
    className="form-control Form_Field_Input"
    onInput={(e) => {
      e.target.value = e.target.value;
    }}
  />
  <ErrorMessage name="Dimensions.Height" component="div" className="text-danger" />
</div>

<div className="col-md-6 Form_Field">
  <div className="Form_Field_Heading">
    Weight
    <span>*</span>
  </div>
  <Field
    type="text"
    name="Dimensions.Weight"
    className="form-control Form_Field_Input"
    onInput={(e) => {
      e.target.value = e.target.value;
    }}
  />
  <ErrorMessage name="Dimensions.Weight" component="div" className="text-danger" />
</div>



                <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Discount Percentage
                    <span>*</span>
                  </div>
                  <Field
                    type="number"
                    name="DiscountPercentage"
                    className="form-control Form_Field_Input"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 2);
                    }}
                  />
                  <ErrorMessage
                    name="DiscountPercentage"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Description
                    <span>*</span>
                  </div>

                  <Field
                    as="textarea"
                    name="ProductDescription"
                    placeholder="Add a comment..."
                    rows="3"
                    className="Form_Field_Input"
                    required
                  />

                  <ErrorMessage
                    name="ProductDescription"
                    component="div"
                    className="text-danger errormsg"
                  />
                </div> */}

                {/* <div className="col-md-12 Form_Field">
                  <div className="Form_Field_Heading">
                    Product Features
                    <span>*</span>
                  </div>
                  <FieldArray
                    name="ProductFeatures"
                    render={(arrayHelpers) => (
                      <div>
                        {values.ProductFeatures.map((_, index) => (
                          <div key={index}>
                            <Field
                              name={`ProductFeatures[${index}]`}
                              className="form-control Form_Field_Input_Array"
                            />
                            <ErrorMessage
                    name="ProductFeatures"
                    component="div"
                    className="text-danger errormsg"
                  />
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                             className="remove_btn"
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                          className="add_btn"
                        >
                          Add Feature
                        </button>
                      </div>
                    )}
                  />
                  
                </div> */}

                {/* <div className="col-md-12 Form_Field py-3 mt-3">
                  <div className="product_description_div">
                  <div className="Form_Field_Heading_heading">
                    Product Capacity
                    <span>*</span>
                  </div>
                  <div className="row">
                    <div className="col-md-3 m-0 p-0">
                      <div className="Form_Field_Heading">
                      Capacity (C)
                        <span>*</span>
                      </div>
                      <Field
                        type="number"
                        name="ProductTechnicalSpecification.capacity"
                        className="Form_Field_Input"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9]/g, "")
                            .slice(0, 2);
                        }}
                      />
                      <ErrorMessage
                        name="ProductTechnicalSpecification.capacity"
                        component="div"
                        className="text-danger  errormsg"
                      />
                    </div>
                  


                  </div>
                </div>
                </div> */}

<div className="Form_Field_Submit_Button">
                <button type="submit">Submit</button>
              </div>
                
              </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      
     {/* conatiner start */}
     <div className="container">
     <div className="col-lg-12 my-2">
     <input
        type="text"
        placeholder="Search By Direct Product Id and product Name"
        value={searchQuery} // Controlled input field
        onChange={handleInputChange} // Trigger search on input change
        style={{
          padding: "8px",
          width: "100%",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />

      </div>

{/* col start */}
<div className="col-lg-12">
  {ProductsData?.length} products found
</div>
<div className="col-lg-12">
<div className="row table_user_data" style={{ overflow: "auto" }}>
   <Table striped bordered hover size="sm">
     <thead>
       <tr>
         <th>S.no</th>
         <th>Category Name</th>
         <th>Sub Category Name</th>
         <th>Product Id</th>

         <th>Product Name</th>
         <th>Product Image</th>
         <th>Product Price</th>
         <th>Product Discount</th>
         <th>Final Price</th>
         <th>Add Specification</th>
         <th>Product Reviews</th>
         <th>actions</th>
       </tr>
     </thead>
     <tbody>
       {Array.isArray(ProductsData) && ProductsData.length > 0 ? (
         ProductsData.filter((ele) => ele?.status !== 2) // Filter out rows with status = 2
           .map((ele, index) => (
             <tr key={ele?._id}>
               <td>{index + 1}</td>
               <td>{ele?.CategoryName}</td>
               <td>{ele?.SubCategoryName}</td>
               <td>{ele?._id}</td>

               <td>{ele?.ProductName}</td>

               <td>
                 <img
                   src={`${cdn_url}${ele?.ProductImage[0]}`}
                   alt={ele?.ProductName}
                   style={{ width: "50px", height: "50px" }}
                 />
               </td>
               <td>{ele?.ProductPrice}</td>
               <td>{ele?.DiscountPercentage}%</td>
               <td>{ele?.PriceAfterDiscount}</td>
               <td>
                <Link to={`/static-product-page/${ele?._id}`}>
                Add Specification</Link></td>
               

               <td onClick={() => handleUpdateProducts(ele)}>
                 Product Reviews
               </td>

               <td>
                 <td>
                   <div className="d-flex align-items-center">
                     <div className="action_button_view">
                       <IoEye />
                     </div>
                     <div
                       className="px-2 action_button_update"
                       onClick={() => handleUpdateProducts(ele)}
                     >
                       <HiPencilSquare className="text-success" />
                     </div>
                     <div
                       className="action_button_delete"
                       onClick={() => handleDeleteProducts(ele)}
                     >
                       <IoTrashBinOutline className="text-danger" />
                     </div>
                   </div>
                 </td>
               </td>
             </tr>
           ))
       ) : (
         <tr>
           <td colSpan="12" className="text-center">
             Data Not Available
           </td>
         </tr>
       )}
     </tbody>
   </Table>
 </div>
</div>
{/* col end */}
</div>
{/* conatiner end */}
      </div>
     

      


     
    </>
  );
};
