import "./App.css";
import CommonLayoutAdmin from "./components/layout/CommonLayoutAdmin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import AdminDashboard from "./pages/AdminDashboard";
import { Category } from "./pages/Category";
import { SubCategory } from "./pages/SubCategory";
import { Products } from "./pages/Products";
import { Testimonials } from "./pages/Testimonials";
import { Aboutus } from "./pages/Aboutus";
import { AboutUsAwards } from "./pages/AboutUsAwards";
import { OnlineStore } from "./pages/OnlineStore";
import { OurBrands } from "./pages/OurBrands";
import { SocialMedia } from "./pages/SocialMedia";
import { OrderMgmt } from "./pages/OrderMgmt";
import { Login } from "./pages/Login";
import { Deal } from "./pages/Deal";
import { HomePageBanner } from "./pages/HomePageBanner";
import { UpdateProduct } from "./pages/UpdateProduct";
import { Blogs } from "./pages/Blogs";
import { UpdateBlogs } from "./pages/UpdateBlogs";
import { UpdateBanner } from "./pages/UpdateBanner";
import { CkEditor } from "./components/ckEditor/CkEditor";
import { StaticPage } from "./pages/StaticPage";
import { StaticPageUpdate } from "./pages/StaticPageUpdate";
import { Ticket } from "./pages/Ticket";
import { TicketDetails } from "./pages/TicketDetails";
import { AdminProtectedRoutes } from "./components/protected/AdminProtectedRoutes";
import { UsersData } from "./pages/UsersData";
import { SingleUser } from "./pages/SingleUser";
import { ContactRequest } from "./pages/ContactRequest";
import { ContactSingleRequest } from "./pages/ContactSingleRequest";
import { StaticProductPage } from "./pages/ProductSPage";
import { StaticProductPageUpdate } from "./pages/StaticProductPageUpdate";


function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/login" element={<Login />} />

          <Route path="/ckeditor" element={<CkEditor />} />


          <Route
          path="/*"
          element={
            <AdminProtectedRoutes>
              <CommonLayoutAdmin>
                <Routes>
                  <Route path="/" element={<AdminDashboard />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/subcategory" element={<SubCategory />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/users" element={<UsersData />} />
                  <Route path="/contactrequest" element={<ContactRequest />} />
                  <Route path="/contactrequest/:id" element={<ContactSingleRequest />} />
                  <Route path="/users/:userId" element={<SingleUser />} />
                  <Route path="/products/createproduct" element={<Products />} />
                  <Route path="/products/update/:productId" element={<UpdateProduct />} />
                  <Route path="/testimonials" element={<Testimonials />} />
                  <Route path="/aboutus/overview" element={<Aboutus />} />
                  <Route path="/aboutus/awards" element={<AboutUsAwards />} />
                  <Route path="/onlinestore" element={<OnlineStore />} />
                  <Route path="/ourbrands" element={<OurBrands />} />
                  <Route path="/homepagebanner" element={<HomePageBanner />} />
                  <Route path="/homepagebanner/update/:bannerId" element={<UpdateBanner />} />
                  <Route path="/staticpage" element={<StaticPage />} />
                  <Route path="/static-product-page/:id" element={<StaticProductPage />} />
                  <Route path="/staticpage/update/:staticpageId" element={<StaticPageUpdate />} />
                  <Route path="/staticpage/update-product/:staticpageId" element={<StaticProductPageUpdate />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blogs/update/:blogsId" element={<UpdateBlogs />} />
                  <Route path="/order-management" element={<OrderMgmt />} />
                  <Route path="/socialmedia" element={<SocialMedia />} />
                  <Route path="/deals" element={<Deal />} />
                  <Route path="/ticket" element={<Ticket />} />
                  <Route path="/ticket/:ticketId" element={<TicketDetails />} />
                </Routes>
              </CommonLayoutAdmin>
            </AdminProtectedRoutes>
          }
        />

        
        </Routes>
      </Router>
    </>
  );
}

export default App;
