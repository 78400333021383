import React from "react";
import { FaStar } from "react-icons/fa";

const StarRating = ({ name, value, onChange }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name={name}
              value={ratingValue}
              onClick={() => onChange(ratingValue)}
              style={{ display: "none" }}
            />
            <FaStar
              size={24}
              color={ratingValue <= value ? "#ffc107" : "#e4e5e9"}
              style={{ cursor: "pointer", marginRight: 5 }}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
