import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetProductReviewsAPI,
  getSingleProducts,
  updateProductReviewsStatusAPI,
  UpdateSingleProducts,
} from "../api/ProductsApi";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { Toaster, toast } from "react-hot-toast";
import {
  FetchAllSubCategoriesWithCategories,
  getallCategory,
} from "../api/CategoryApi";
import { getAllOurBrands } from "../api/OurBrandsApi";
import { getDecryptedCookie } from "../utils/Utils";
import { Table } from "react-bootstrap";
import axios from "axios";
import { Base_Url } from "../constant/Url";

export const UpdateProduct = () => {
  const { productId } = useParams();

  const [Reviews, setReviews] = useState([]);

  const [singleProductData, setSingleProductData] = useState(null);
  const [categoryListData, setcategoryListData] = useState([]);
   const [SelectedCategory, setSelectedCategory] = useState();
  const [SubCategoryListData, setSubCategoryListData] = useState([]);
  const [AllShopsData, setAllShopsData] = useState([]);
  const [image, setImage] = useState(null); // To store the selected image
  
  const [error, setError] = useState(null); // To store error messages
  const [success, setSuccess] = useState(false); // To show success message


console.log(SelectedCategory,'SubCategoryListDataSubCategoryListData')
  const getSingleProductDetails = async () => {
    try {
      const response = await getSingleProducts(productId);
      console.log(response);
      if (response?.status == "success") {
        setSingleProductData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleProductReviewsDetails = async () => {
    const AdminData = getDecryptedCookie("AdminData");
    const token = AdminData?.token;

    try {
      const response = await GetProductReviewsAPI(token);

      if (response?.status == "success") {
        setReviews(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProductReviewsDetails();
  }, []);

  const FetchAllCategoryList = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setcategoryListData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  

  const FetchAllShops = async () => {
    try {
      const res = await getAllOurBrands();
      if (res?.status === "success") {
        setAllShopsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const handleCategoryChange = (e, setFieldValue) => {
    const selectedCategory = e.target.value;
    const selectedCategoryData = categoryListData?.find(
      (data) => data.CategoryName === selectedCategory
    );
    setFieldValue("CategoryName", selectedCategory);
    setFieldValue(
      "CategoryNameSlug",
      selectedCategoryData?.CategoryNameSlug || ""
    );
    setSelectedCategory(selectedCategoryData?.CategoryNameSlug || "");
  };

  const handleSubCategoryChange = (e, setFieldValue) => {
    const selectedSubCategory = e.target.value;
    const selectedSubCategoryData = SubCategoryListData?.find(
      (data) => data.SubCategoryName === selectedSubCategory
    );
    setFieldValue("SubCategoryName", selectedSubCategory);
    setFieldValue(
      "SubCategoryNameSlug",
      selectedSubCategoryData?.SubCategoryNameSlug || ""
    );
  };
  const getSubCategoryName = async () => {
    const data = {
      CategoryNameSlug: SelectedCategory,
    };
    try {
      const response = await FetchAllSubCategoriesWithCategories(data);
      if (response.status === "success") {
        setSubCategoryListData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
useEffect(() => {
    FetchAllShops();
    FetchAllCategoryList();
  
  }, []);

  useEffect(() => {
    getSubCategoryName();
  }, [SelectedCategory]);

  const validationSchema = Yup.object({
    CategoryName: Yup.string().required("Category Name is required"),
    SubCategoryName: Yup.string().required("Sub Category Name is required"),
    ProductName: Yup.string().required("Product Name is required"),
    ProductNameSlug: Yup.string().required("Product Name Slug is required"),
    

    ProductPrice: Yup.number().positive("Must be a positive number"),
   

  });

  const handleSubmit = async (values, { resetForm }) => {
    // Prepare FormData for the request
    const formData = new FormData();
    
    // Add the string fields to the FormData
    formData.append("CategoryName", values.CategoryName);
    formData.append("CategoryNameSlug", values.CategoryNameSlug);
  
    formData.append("SubCategoryName", values.SubCategoryName);
    formData.append("SubCategoryNameSlug", values.SubCategoryNameSlug);
  
    formData.append("ProductName", values.ProductName);
    formData.append("ProductNameSlug", values.ProductNameSlug);
  
    formData.append("ProductDescription", values.ProductDescription);
  
    formData.append("ShopName", values.ShopName);
    formData.append("ShopNameSlug", values.ShopNameSlug);
  
    formData.append("ProductPrice", Number(values.ProductPrice));
    formData.append("DiscountPercentage", values.DiscountPercentage);
    if (values.Dimensions) {
      formData.append("Length", values.Dimensions.Length || ""); // Append Length
      formData.append("Breadth", values.Dimensions.Breadth || ""); // Append Breadth
      formData.append("Height", values.Dimensions.Height || ""); // Append Height
      formData.append("Weight", values.Dimensions.Weight || ""); // Append Weight
    }
    // Add images (if any) to FormData
    if (values.ProductImage) {
      Array.from(values.ProductImage).forEach((file) => {
        formData.append("ProductImage", file);
      });
    }
  
    // Log form data (for debugging purposes)
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
  
    // Call API to update product
    try {
      const res = await UpdateSingleProducts(productId, formData);
      console.log(res, "UpdateSingleProductsUpdateSingleProducts");
  
      // If update is successful, show alert and reload the page
      if (res?.data?.status === "success") {
        alert("Product updated successfully!"); // Show success alert
        // window.location.reload(); 
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating the product. Please try again!"); // Show error alert
    }
  };
  

// **Show toast only if the flag is set in sessionStorage**
// useEffect(() => {
//   if (sessionStorage.getItem("productUpdated") === "true") {
//     alert("Product Updated Successfully!"); // Show alert
//     sessionStorage.removeItem("productUpdated"); // Remove flag after showing alert
//   }
// }, []);



  const [loading, setLoading] = useState(false);

  const handleStatusChange = async (reviewId, newStatus) => {
    try {
      setLoading(true);

      // Call the update API
      const response = await axios.put(
        `${Base_Url}/product/reviews/${productId}/${reviewId}`,
        { status: newStatus }
      );

      if (response.data.status === "success") {
        // Update the review status in the UI
        setReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === reviewId ? { ...review, status: newStatus } : review
          )
        );
      }
    } catch (error) {
      console.error("Error updating review status:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleProductDetails();
  }, []);
// Handle form submission to update the primary product image
 // Handle image file change
 const handleFileChange = (e) => {
  const file = e.target.files[0];
  setImage(file); // Set the selected file in the state
};
const handleImageUpload = async (e) => {
  e.preventDefault();
  
  if (!image) {
    setError("Please select an image to upload.");
    return;
  }

  const formData = new FormData();
  formData.append('ProductPImage', image); // Append the image to form data

  setLoading(true); // Start loading

  try {
    // Send the image to the backend to update the primary images
    const response = await axios.put(`${Base_Url}/product/updateprimaryimage/${productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      setSuccess(true); // Set success state if the upload is successful
      setError(null); // Clear any previous error
      console.log("Primary image updated:", response.data);
    }
  } catch (error) {
    setError("An error occurred while updating the image.");
    console.error(error);
  } finally {
    setLoading(false); // Set loading state to false after the request
  }
};
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      {/* section start */}
      <section className="update_products_section">
        {/* container start */}
        <div className="container">
         
          {/* row start */}
          <div className="row">

 {/* col start */}
 <div className="col-lg-12">
  {/* product update title div start */}
  <div className="product_update_title_div">
              <h1>Update Product</h1>
              <p> <b>Updating product with ID:</b> {productId}</p>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <div>
      <h2>Update Primary Image</h2>
      <form onSubmit={handleImageUpload}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Update Image"}
        </button>
      </form>

      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>Primary image updated successfully!</div>}
    </div>
</div>
              {/* product update title div start */}
 </div>
 {/* col end */}


            {/* col start */}
            <div className="col-lg-12">
            <Formik
        enableReinitialize={true} // This ensures form updates when singleProductData is fetched
        initialValues={{
          CategoryName: singleProductData?.CategoryName || "",
          CategoryNameSlug: singleProductData?.CategoryNameSlug || "",

          SubCategoryName: singleProductData?.SubCategoryName || "",
          SubCategoryNameSlug: singleProductData?.SubCategoryNameSlug || "",

          ProductName: singleProductData?.ProductName || "",
          ProductNameSlug: singleProductData?.ProductNameSlug || "",

          dealsType: singleProductData?.dealsType || "",
          dealsTypeSlug: "",

          ProductDescription: singleProductData?.ProductDescription || "",
          ShopName: singleProductData?.ShopName || "",
          ShopNameSlug: singleProductData?.ShopNameSlug || "",

          ProductPrice: singleProductData?.ProductPrice || "",
          DiscountPercentage: singleProductData?.DiscountPercentage || "",

          ProductFeatures: singleProductData?.ProductFeatures || [""],
          ProductImage: singleProductData?.ProductImage || null,
Dimensions: {
  Length: singleProductData?.Dimensions?.Length || "",   // Correctly referencing Length
  Breadth: singleProductData?.Dimensions?.Breadth || "", // Correctly referencing Breadth
  Height: singleProductData?.Dimensions?.Height || "",   // Correctly referencing Height
  Weight: singleProductData?.Dimensions?.Weight || "",   // Correctly referencing Weight
},
          ProductTechnicalSpecification: {
            Power:
              singleProductData?.ProductTechnicalSpecification?.Power || "",
            Voltage:
              singleProductData?.ProductTechnicalSpecification?.Voltage || "",
            Capacity:
              singleProductData?.ProductTechnicalSpecification?.Capacity || "",
            Material:
              singleProductData?.ProductTechnicalSpecification?.Material || "",
          },
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
 {({ values, setFieldValue, errors, touched }) => (
    <Form>
      {/* nested row start */}
      <div className="row">

        {/* nested col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Product Name <span>*</span></label>
            <Field type="text"  name="ProductName" className="form-control"/>
              <ErrorMessage name="ProductName" component="div" className="text-danger"/>
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}
       

         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Product Name Slug <span>*</span></label>
            <Field
                      type="text"
                      name="ProductNameSlug"
                       className="form-control"
                      onChange={(e) => {
                        const slug = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z-]/g, "") // Remove invalid characters (numbers, special chars)
                          .replace(/-+/g, "-"); // Replace multiple dashes with single dash
                        setFieldValue("ProductNameSlug", slug);
                      }}
                    />
                    <ErrorMessage
                      name="ProductNameSlug"
                      component="div"
                      className="text-danger"
                    />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label>  Category Name <span>*</span></label>
            <Field
                  as="select"
                  name="CategoryName"
                  
                  onChange={(e) => {
                    handleCategoryChange(e, setFieldValue);
                    setFieldValue("CategoryName", e.target.value); // Update Formik value as well
                  }}
                >
                  <option value="">Select a Category Name</option>

                  {categoryListData?.map((category) => (
                    <option key={category._id} value={category.CategoryName}>
                      {category.CategoryName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="CategoryName"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Sub Category Name <span>*</span></label>
            <Field
                  as="select"
                  name="SubCategoryName"
                  
                  onChange={(e) => {
                    handleSubCategoryChange(e, setFieldValue);
                    setFieldValue("SubCategoryName", e.target.value); // Update Formik value as well
                  }}
                >
                  <option value="">Select a Sub Category Name</option>

                  {SubCategoryListData?.map((subcategory) => (
                    <option
                      key={subcategory._id}
                      value={subcategory.SubCategoryName}
                    >
                      {subcategory.SubCategoryName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="CategoryName"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12" hidden>
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Deal Name Name <span>*</span></label>
            <Field
                  as="select"
                  name="dealsType"
                  // onChange={(e) => handleDealChange(e, setFieldValue)}
                >
                  <option value="">Select a Deal</option>
                  {/* {DealsData?.map((item) => {
                    return (
                      <option key={item?._id} value={item?.dealName}>
                        {item?.dealName}
                      </option>
                    );
                  })} */}
                </Field>
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label>Shop Name <span>*</span></label>
            <Field
                  as="select"
                  name="ShopName"
                  
                  // onChange={(e) => {
                  //   handleShopChange(e, setFieldValue);
                  //   setFieldValue("ShopName", e.target.value); // Update Formik value as well
                  // }}
                >
                  <option value="">Select a Shop</option>

                  {AllShopsData?.map((shop) => (
                    <option key={shop._id} value={shop.OurBrandsName}>
                      {shop.OurBrandsName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="ShopName"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Product Images <span>*</span></label>
            <input
             className="form-control"
                  name="ProductImage"
                  type="file"
                  multiple
                  onChange={(e) =>
                    setFieldValue("ProductImage", e.currentTarget.files)
                  }
                />

                <ErrorMessage
                  name="ProductImage"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


         {/* nested col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Product Price <span>*</span></label>
            <Field
                  type="number"
                  name="ProductPrice"
                   className="form-control"
                />
                <ErrorMessage
                  name="ProductPrice"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}

         <div className="col-md-6 Form_Field">
          <div className="Form_Field_Heading">
            Length
            <span>*</span>
          </div>
          <Field
            type="text"
            name="Dimensions.Length"
            className="form-control Form_Field_Input"
            onInput={(e) => {
              e.target.value = e.target.value;
            }}
          />
          <ErrorMessage name="Dimensions.Length" component="div" className="text-danger" />
        </div>
        
        <div className="col-md-6 Form_Field">
          <div className="Form_Field_Heading">
            Breadth
            <span>*</span>
          </div>
          <Field
            type="text"
            name="Dimensions.Breadth"
            className="form-control Form_Field_Input"
            onInput={(e) => {
              e.target.value = e.target.value;
            }}
          />
          <ErrorMessage name="Dimensions.Breadth" component="div" className="text-danger" />
        </div>
        
        <div className="col-md-6 Form_Field">
          <div className="Form_Field_Heading">
            Height
            <span>*</span>
          </div>
          <Field
            type="text"
            name="Dimensions.Height"
            className="form-control Form_Field_Input"
            onInput={(e) => {
              e.target.value = e.target.value;
            }}
          />
          <ErrorMessage name="Dimensions.Height" component="div" className="text-danger" />
        </div>
        
        <div className="col-md-6 Form_Field">
          <div className="Form_Field_Heading">
            Weight
            <span>*</span>
          </div>
          <Field
            type="text"
            name="Dimensions.Weight"
            className="form-control Form_Field_Input"
            onInput={(e) => {
              e.target.value = e.target.value;
            }}
          />
          <ErrorMessage name="Dimensions.Weight" component="div" className="text-danger" />
        </div>
        
        


         {/* nested col start */}
         <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {/* input main div start */}
          <div className="input_main_div_group">
            <label> Discount Percentage <span>*</span></label>
            <Field
                  type="number"
                  name="DiscountPercentage"
                   className="form-control"
                />
                <ErrorMessage
                  name="DiscountPercentage"
                  component="div"
                  className="text-danger"
                />
          </div>
          {/* input main div start */}

        </div>
        {/* nested col end */}


        


        

         {/* nested col start */}
         <div className="col-lg-12 col-md-12 col-sm-12 col-12">

         

{/* nested row start */}
<div className="row">



           {/* col start  */}
           <div className="col-lg-12 col-md-12 col-sm-12 col-12">
             {/* input main div start */}
             <div className="input_main_div_btn_div">
             <button  className="submit_btn_update_prod" type="submit">Submit</button>
            </div>
          {/* input main div start */}
          </div>
          {/* col end */}




          </div>
{/* nested row end */}
         </div>
        {/* nested col end */}




      </div>
      {/* nested row end */}
    </Form>
 )}

        </Formik>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* section end */}

      

     

      {/* Reviews Table */}
     <div className="container">
      <div className="row">
        <div className="table_update_products">
          <table className="table table-bordred table-md">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Product ID</th>
                <th>User Details</th>
                <th>Rating</th>
                <th>Status</th>
                <th>Comment</th>
                <th>createdAt</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(Reviews) && Reviews?.length > 0 ? (
                Reviews.map((ele, index) => (
                  <tr key={ele?._id}>
                    <td>{index + 1}</td>
                    <td>{ele?.productId}</td>

                    <td>
                      <div>
                        {ele?.userDetails?.name
                          ? ele?.userDetails?.name
                          : ele?.userDetails?.mobile}
                      </div>

                      <div>{ele?.userId}</div>
                    </td>
                    <td>{ele?.rating}</td>
                    {/* Dropdown for updating status */}
                    <td>
                      <select
                        value={ele.status}
                        onChange={(e) =>
                          handleStatusChange(ele._id, Number(e.target.value))
                        }
                        disabled={loading}
                        className={ele.status === 0 ? "pending_slct" : "approved_slct"}
                      >
                        <option value={0}>Pending</option>
                        <option value={1}>Approved</option>
                      </select>
                    </td>

                    <td>
                      {ele?.comment?.length > 40
                        ? ele?.comment?.slice(0, 60) + "..."
                        : ele?.comment}
                    </td>
                    <td>{ele?.createdAt}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  );
};
