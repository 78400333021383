import React, { useEffect, useState } from "react";
import { getallUserData } from "../api/Api";
import { getallTestimonialData } from "../api/TestimonialApi";
import { getallProducts } from "../api/ProductsApi";
import { getallCategory } from "../api/CategoryApi";
import { getallSubCategory } from "../api/SubCategoryApi";
import { NavLink } from "react-router-dom";
import { getAllOnlineStore } from "../api/OnlineStoreApi";
import { getallSocialMedia } from "../api/SocialMediaApi";
import { getAllBlogs } from "../api/BlogsApi";
import { getAllBannersApi } from "../api/HomePageBannersApi";
import { getAllOurBrands } from "../api/OurBrandsApi";
import { getDecryptedCookie } from "../utils/Utils";
import { getAllstaticpage } from "../api/StaticPagesApi";
import { IoTicketOutline } from "react-icons/io5";
import { GetAllUserTicket } from "../api/TicketApi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import axios from "axios";
import { Base_Url } from "../constant/Url";
import { getAllDeals } from "../api/DealsApi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getallContactRequest } from "../api/ContactRequestApi";

const AdminDashboard = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [ProductData, setProductData] = useState([]);
  const [UserData, setUserData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [OnlineStoreData, setOnlineStoreData] = useState([]);
  const [SocialMediaData, setSocialMediaData] = useState([]);
  const [contactRequest, setContactRequest] = useState([]);

  const [OurBrandsData, setOurBrandsData] = useState([]);
  const [StaticPagesData, setStaticPagesData] = useState([]);
  const [userTickets, setUserTickets] = useState([]);
  const [BannersData, SetBannersData] = useState([]);
  const [BlogsData, setBlogsData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [DealsData, SetDealsData] = useState([]);

  const AdminData = getDecryptedCookie("AdminData");
  const token = AdminData?.token;

  const FetchAllTestimonials = async () => {
    try {
      const res = await getallTestimonialData();
      if (res?.status === "success") {
        setTestimonialsData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllUser = async () => {
    try {
      const res = await getallUserData();
      if (res?.status === "success") {
        setUserData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllBanners = async () => {
    try {
      const response = await getAllBannersApi();
      if (response?.status === "success") {
        SetBannersData(response?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllOurBrands = async () => {
    try {
      const res = await getAllOurBrands();
      if (res?.status === "success") {
        setOurBrandsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const fetchAllDeals = async () => {
    try {
      const response = await getAllDeals(token);
      if (response?.status === "success") {
        SetDealsData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllProducts = async () => {
    try {
      const res = await getallProducts();
      if (res?.status === "success") {
        setProductData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllCategory = async () => {
    try {
      const res = await getallCategory();
      if (res?.status === "success") {
        setCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllSubCategory = async () => {
    try {
      const res = await getallSubCategory();
      if (res?.status === "success") {
        setSubCategoryData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllOnlineStore = async () => {
    try {
      const res = await getAllOnlineStore();
      if (res?.status === "success") {
        setOnlineStoreData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllSocialMedia = async () => {
    try {
      const res = await getallSocialMedia();
      if (res?.status === "success") {
        setSocialMediaData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllBlogs = async () => {
    try {
      const res = await getAllBlogs();
      if (res?.status === "success") {
        setBlogsData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllStaticPages = async () => {
    try {
      const res = await getAllstaticpage(token);
      if (res?.status === "success") {
        setStaticPagesData(res?.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  const FetchAllUserTickets = async () => {
    try {
      const res = await GetAllUserTicket(token);
      if (res?.status === "success") {
        setUserTickets(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${Base_Url}/order/admin/orders`);
      setOrders(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const FetchAllRequests = async () => {
    try {
      const res = await getallContactRequest(token);
      if (res?.status === "success") {
        setContactRequest(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllRequests();
    FetchAllTestimonials();
    FetchAllUser();
    fetchAllDeals();
    FetchAllSubCategory();
    FetchAllCategory();
    FetchAllProducts();
    FetchAllOnlineStore();
    FetchAllSocialMedia();
    fetchOrders();
    FetchAllBlogs();
    FetchAllBanners();
    FetchAllOurBrands();
    FetchAllStaticPages();
    FetchAllUserTickets();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bx-image"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Banners</h6>

                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>

                  <span>
                    {BannersData ? BannersData.length : <Skeleton width={20} />}
                  </span>
                </div>

                {CategoryData ? (
                  <NavLink to="/homepagebanner">
                    <span className="text">Get Full Details</span>
                  </NavLink>
                ) : (
                  <Skeleton width={100} />
                )}
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bxs-category"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Categories</h6>

                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{CategoryData?.length}</span>
                </div>
                <NavLink to="/category">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bx-category"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Sub Categories</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{SubCategoryData?.length}</span>
                </div>
                <NavLink to="/subcategory">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bx-user"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Users</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{UserData?.length}</span>
                </div>
                <NavLink to="/users">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bx-sitemap"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Products</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{ProductData?.length}</span>
                </div>
                <NavLink to="/products">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bx-notepad"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Testimonials</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{testimonialsData?.length}</span>
                </div>
                <NavLink to="/testimonials">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bxl-meta"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Total Social Media</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{SocialMediaData?.length}</span>
                </div>
                <NavLink to="/socialmedia">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bxs-store"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">Our Brands</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{OurBrandsData?.length}</span>
                </div>
                <NavLink to="/ourbrands">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <i class="bx bxs-file-doc"></i>
              </div>
              <div className="px-2">
                <h6 className="m-0">All Static Pages</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{StaticPagesData?.length}</span>
                </div>
                <NavLink to="/staticpage">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <IoTicketOutline />
              </div>
              <div className="px-2">
                <h6 className="m-0">All Tickets</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>

                  <span>{userTickets?.length}</span>
                </div>
                <NavLink to="/ticket">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <MdOutlineShoppingCart />
              </div>
              <div className="px-2">
                <h6 className="m-0">Order Management</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>

                  <span>{orders?.length}</span>
                </div>
                <NavLink to="/order-management">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <IoPricetagsOutline />
              </div>
              <div className="px-2">
                <h6 className="m-0">All Deals</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{DealsData?.length}</span>
                </div>
                <NavLink to="/deals">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-4 p-2">
            <div className="dashboard_cards d-flex align-items-center">
              <div className="dashboard_cards_icon">
                <IoPricetagsOutline />
              </div>
              <div className="px-2">
                <h6 className="m-0">Contact Requests</h6>
                <div className="d-flex align-items-center fw-bold">
                  <i class="bx bx-trending-up bx-flashing text-success fw-bold pe-1"></i>
                  <span>{contactRequest?.length}</span>
                </div>
                <NavLink to="/contactrequest">
                  <span className="text">Get Full Details</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
