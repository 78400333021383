import axios from "axios";
import { Base_Url } from "../constant/Url";

export const createBlogs = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/blogs/createblogs`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating Blogs", error);
    throw error;
  }
};

export const getAllBlogs = async () => {
  try {
    const res = await fetch(`${Base_Url}/blogs/getallblogs`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteBlogApi = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/blogs/deletesingleblog/${id}`, {
      method: "Delete",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const getSingleBlog = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/blogs/getsingleblogs/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const updateBlogs = async (id, formData) => {
  try {
    const response = await axios.put(
      `${Base_Url}/blogs/updatesingleblog/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error while updating category:", error);
    throw error;
  }
};
