import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";

import { getDecryptedCookie } from "../utils/Utils";
import {
  DeleteUserTicket,
  GetAllUserTicket,
  ReOpenUserTicket,
  UpdateStatusUserTicket,
} from "../api/TicketApi";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Button } from "react-bootstrap";

export const Ticket = () => {
  const [userTickets, setUserTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const AdminData = getDecryptedCookie("AdminData"); // Get the decrypted user data from cookies

  const token = AdminData?.token;
  const FetchAllUserTickets = async () => {
    setLoading(true);
    try {
      const res = await GetAllUserTicket(token);
      if (res?.status === "success") {
        setUserTickets(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const HandleFetchSingleTicketDetails = (id) => {
    navigate(`${id}`);
  };

  const HandleDeleteTicket = async (id) => {
    try {
      const res = await DeleteUserTicket(id, token);
      if (res?.status === "success") {
        toast.success(res?.message, { autoClose: 3000 });
        FetchAllUserTickets();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const HandleReopenTicket = async (ticket) => {
  //   try {
  //     const updatedStatus = ticket?.status === "open" ? "closed" : "open";

  //     const data = {
  //       status: updatedStatus,
  //     };

  //     const res = await UpdateStatusUserTicket(ticket?._id, token, data);
  //     if (res?.status == "success") {
  //       toast.success(res?.message, { autoClose: 3000 });
  //       FetchAllUserTickets();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const HandleCloseTicket = async (ticket) => {
    try {
      if (ticket?.status !== "open") {
        toast.warning("Only open tickets can be closed.", { autoClose: 3000 });
        return;
      }

      const data = {
        status: "closed", // Admin can only close tickets
      };

      const res = await UpdateStatusUserTicket(ticket?._id, token, data);
      if (res?.status === "success") {
        toast.success(res?.message, { autoClose: 3000 });
        FetchAllUserTickets();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchAllUserTickets();
  }, []);

  return (
    <div>
      <Toaster />

      <div className="row">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Ticket Id</th>
                <th>Customer Details</th>
                <th>Ticket Details</th>
                <th>Created</th>
                <th>Updated</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                [...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton width={30} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                      <Skeleton width={100} />
                    </td>

                    <td>
                      <Skeleton width={100} />
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={100} />
                    </td>
                    <td>
                      <Skeleton width={50} />
                    </td>
                    <td>
                      <Skeleton width={30} />
                    </td>
                    <td>
                      <Skeleton width={50} />
                      <Skeleton width={50} />
                    </td>
                  </tr>
                ))
              ) : userTickets.length > 0 ? (
                userTickets.map((ele, index) => (
                  <tr key={index} style={{ fontSize: "12x" }}>
                    <td>{index + 1}</td>
                    <td>{ele?._id}</td>
                    <td>
                      <div> {ele?.userDetails?.name}</div>
                      <div> {ele?.userDetails?.mobile}</div>
                    </td>
                    <td>
                      <div>{ele?.subject}</div>

                      <div>
                        {ele?.description?.length > 40
                          ? ele?.description?.slice(0, 60) + "..."
                          : ele?.description}
                      </div>
                    </td>

                    <td>
                      <div>
                        {new Date(ele?.createdAt)?.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                      <div>
                        {new Date(ele?.createdAt)?.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </div>
                    </td>
                    <td>
                      <div>
                        {new Date(ele?.updatedAt)?.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                      <div>
                        {new Date(ele?.updatedAt)?.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </div>
                    </td>
                    <td>{ele?.status}</td>

                    <td>
                      {/* <p>
                        {ele?.status !== "open" ? (
                          <span
                            className="bg-info"
                            onClick={() => HandleReopenTicket(ele)}
                          >
                            Reopen Ticket
                          </span>
                        ) : (
                          <span
                            className="bg-warning"
                            onClick={() => HandleReopenTicket(ele)}
                          >
                            Close Ticket
                          </span>
                        )}
                      </p>
                      <p
                        className="bg-warning"
                        onClick={() => HandleFetchSingleTicketDetails(ele?._id)}
                      >
                        View Ticket Details
                      </p> */}

                      <p>
                        {ele?.status === "open" && (
                          <span
                            className="close_ticket_btn"
                            onClick={() => HandleCloseTicket(ele)}
                          >
                            Close Ticket
                          </span>
                        )}
                      </p>
                      <button
                        className="reply_tickt_btn"
                        onClick={() => HandleFetchSingleTicketDetails(ele?._id)}
                      >
                        Reply Ticket
                      </button>

                      {/* <p
                        className="bg-danger"
                        onClick={() => HandleDeleteTicket(ele?._id)}
                      >
                        Delete Ticket
                      </p> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      </div>
   
  );
};
