import React from "react";
import { Navigate } from "react-router-dom";
import { getDecryptedCookie } from "../../utils/Utils";
import toast, { Toaster } from "react-hot-toast";

export const AdminProtectedRoutes = ({ children }) => {
  const AdminData = getDecryptedCookie("AdminData");
  console.log(AdminData)

  // Check if token exists and role is admin
  const isAuthenticated = AdminData?.token && AdminData?.role === "admin";
  // const isAuthenticated = AdminData?.token ;
  // console.log(isAuthenticated)

  if (!isAuthenticated) {
    toast.error("Access Denied! You are not authorized to view this page.");
    return <Navigate to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};
