import { Base_Url } from "../constant/Url";

export const GetAllUsersApi = async (token) => {
  try {
    const res = await fetch(`${Base_Url}/user/allUser`, {
      method: "Get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetUserProfile = async (userId, token) => {
  try {
    if (!token) {
      throw new Error("Token is required for fetching the profile");
    }

    const requestData = { userId }; // Ensure userId is passed correctly

    const res = await fetch(`${Base_Url}/user/getsingleuserprofile`, {
      method: "POST", // Change to GET if your API expects it
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Pass token in headers
      },
      body: JSON.stringify(requestData), // Send request body correctly
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.log("An error occurred:", error);
    return {
      success: false,
      message:
        error.message || "An error occurred while fetching the User Profile.",
    };
  }
};

export const UpdateUserStatusByAdmin = async (userId, status, token) => {
  try {
    const res = await fetch(`${Base_Url}/user/updateuserstatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, status }),
    });

    const data = await res.json();
    return data;
  } catch (error) {
    console.log("Error updating user status:", error);
    return { success: false, message: "Failed to update user status" };
  }
};

