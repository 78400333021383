import axios from "axios";
import { Base_Url } from "../constant/Url";

export const refundApi = async (amount, paymentId) => {
    try {
      const response = await axios.post(
        `${Base_Url}/paymentgateway/refund`,
        {
          amount, 
          payment_id: paymentId, // Ensure correct key as expected by API
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error processing refund:", error);
      throw error;
    }
  };
  