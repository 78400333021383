import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getSingleProductStaticPages, updateProductstaticpage, updatestaticpage } from "../api/StaticPagesApi";
import { CkEditor } from "../components/ckEditor/CkEditor";
import { Toaster, toast } from "react-hot-toast";
import { getDecryptedCookie } from "../utils/Utils";
import * as Yup from "yup";
export const StaticProductPageUpdate = () => {

  const AdminData = getDecryptedCookie("AdminData");
    const token = AdminData?.token;

  const { staticpageId } = useParams();
  const [initialValues, setInitialValues] = useState({
    staticPageName: "",
    staticPageNameSlugUrl: "",
    staticPageDescription: "",
    Status: "",
  });

  useEffect(() => {
    const getSingleStaticPageDetails = async () => {
      try {
        const response = await getSingleProductStaticPages(staticpageId);
        if (response?.status === "success" && response.data) {
          setInitialValues({
            staticPageName: response.data.staticPageName || "",
            staticPageNameSlugUrl: response.data.staticPageNameSlugUrl || "", // Fix the key name
            staticPageDescription: response.data.staticPageDescription || "",
            Status: "",
          });
        }
      } catch (error) {
        console.log("Error fetching static page details:", error);
      }
    };

    if (staticpageId) {
      getSingleStaticPageDetails();
    }
  }, [staticpageId]); // Add dependency here
const validationSchema = Yup.object({
    staticPageName: Yup.string().required("static Page Name is required"),
 
    staticPageDescription: Yup.string().required(
      "static Page Description is required"
    ),
    Status: Yup.string().required("Status is required"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    // Show confirmation dialog first
    const isConfirmed = window.confirm("Are you sure you want to update the static page?");
    
    if (!isConfirmed) {
      // If user cancels, reload the page and return early
      console.log("User canceled the update.");
      window.location.reload(); // Reload the page if user cancels
      return;
    }
    
    // If confirmed, proceed with preparing the form data
    const formData = new FormData();
    formData.append("staticPageName", values.staticPageName);
    formData.append("staticPageNameSlugUrl", values.staticPageNameSlugUrl);
    formData.append("staticPageDescription", values.staticPageDescription);
    formData.append("Status", values.Status);
    
    // Log form data for debugging
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
    
    try {
      // Call the API to update the static page
      const res = await updateProductstaticpage(formData, staticpageId, token);
      
      if (res?.data?.status === "success") {
        // Show success toast
        toast.success("Static Page Updated Successfully!", { duration: 3000 });
        
        // After success, ask if the user wants to reload the page
        const isReloadConfirmed = window.confirm("Static page updated successfully. Do you want to reload the page?");
        
        // Reload the page if the user confirms, otherwise return without reloading
        if (isReloadConfirmed) {
          window.location.reload(); // The page reload happens if user confirms
        }
      }
    } catch (error) {
      console.error("Error updating static page:", error);
    }
  };



  return (
    <div>
      <Toaster />
      <h1>Update Static Page</h1>
      
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div>
              <label htmlFor="staticPageName">Static Page Name</label>
              <Field name="staticPageName" type="text" />
              <ErrorMessage name="staticPageName" component="div" />
            </div>

           

            <div>
              <label htmlFor="staticPageDescription">Description</label>
              <CkEditor
                value={values?.staticPageDescription}
                onChange={(content) =>
                  setFieldValue("staticPageDescription", content)
                }
              />
            </div>

            <div className="mt-2">
              <div>Status</div>
              <Field as="select" name="Status" className="Form_Field">
                <option value="">Select Status</option>
                <option value="approve">Approve</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </Field>
              <ErrorMessage
                name="Status"
                component="div"
                className="text-danger"
              />
            </div>

            <button type="submit">Update Page</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
