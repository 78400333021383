import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../styles/Sidebar.css";
import "../../styles/UserData.css";
import Cookies from "js-cookie";
import { MdLogout, MdOutlineShoppingCart } from "react-icons/md";
import { IoPricetagsOutline, IoTicketOutline } from "react-icons/io5";
import { VscGitPullRequest } from "react-icons/vsc";
import { LuLayoutDashboard } from "react-icons/lu";

export const Sidebar = ({ children }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLinks, setFilteredLinks] = useState([]);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  const sidebarLinks = [
    { name: "Dashboard", path: "/", icon: <LuLayoutDashboard /> },
    { name: "Home Page Banner", path: "/homepagebanner", icon: "🏠" },
    { name: "Category", path: "/category", icon: "📂" },
    { name: "Sub-Category", path: "/subcategory", icon: "📂" },
    { name: "All Users", path: "/users", icon: "👤" },
    { name: "Products", path: "/products", icon: "🛒" },
    { name: "Testimonials", path: "/testimonials", icon: "📝" },
    { name: "Social Media", path: "/socialmedia", icon: "🌐" },
    { name: "Our Brands", path: "/ourbrands", icon: "🏬" },
    { name: "Static Page", path: "/staticpage", icon: "📄" },
    { name: "Ticket", path: "/ticket", icon: <IoTicketOutline /> },
    {
      name: "Order Management",
      path: "/order-management",
      icon: <MdOutlineShoppingCart />,
    },
    { name: "Deals", path: "/deals", icon: <IoPricetagsOutline /> },
    {
      name: "Customer inquiry",
      path: "/contactrequest",
      icon: <VscGitPullRequest />,
    },
  ];

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value) {
      const filtered = sidebarLinks.filter((link) =>
        link.name.toLowerCase().includes(value)
      );
      setFilteredLinks(filtered);
    } else {
      setFilteredLinks([]);
    }
  };

  // Handle selecting a search result
  const handleSelectSearch = (path) => {
    setSearchTerm("");
    setFilteredLinks([]);
    navigate(path);
  };

  const handleLogout = () => {
    try {
      // Remove user cookie
      Cookies.remove("AdminData");

      // Clear any local/session storage
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();

      // Navigate to login page
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error.message);
    }
  };

  return (
    <div>
      <div
        className={`sidebar ${isSidebarActive ? "active" : ""}`}
        style={{ overflow: "auto" }}
      >
        {/* sidebar code */}
        <div className="logo-details bg-white" style={{ height: "65px" }}>
          <NavLink to="/" className="text-decoration-none bg-warning">
            <div className="bg-white">
              <img
                src="https://www.balajiwaterpurifier.com/assets/logo-color-ZtJsjKfv.png"
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </NavLink>
        </div>

        <ul className="nav-links p-0">
          {sidebarLinks.map((link, index) => (
            <li key={index}>
              <NavLink to={link.path}>
                <i>{link.icon}</i>
                <span className="links_name">{link.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>

        {/* <ul className="nav-links p-0">
          <li>
            <NavLink to="/">
              <i>
                <LuLayoutDashboard />
              </i>
              <span className="links_name">Dashboard</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/homepagebanner">
              <i class="bx bx-image"></i>
              <span className="links_name">Home Page Banner</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/category">
              <i class="bx bxs-category"></i>
              <span className="links_name">Category</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/subcategory">
              <i class="bx bx-category"></i>
              <span className="links_name">Sub-Category</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/users">
              <i class="bx bx-user"></i>
              <span className="links_name">All Users</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/products">
              <i class="bx bx-sitemap"></i>
              <span className="links_name">Products</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/testimonials">
              <i class="bx bx-notepad"></i>
              <span className="links_name">Testimonials</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/socialmedia">
              <i class="bx bxl-meta"></i>
              <span className="links_name">Social Media</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/ourbrands">
              <i class="bx bxs-store"></i>
              <span className="links_name">Our Brands</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/staticpage">
              <i class="bx bxs-file-doc"></i>
              <span className="links_name">Static Page</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/ticket">
              <i>
                <IoTicketOutline />
              </i>
              <span className="links_name">Ticket</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/order-management">
              <i>
                <MdOutlineShoppingCart />
              </i>
              <span className="links_name">Order Management</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/deals">
              <i>
                <IoPricetagsOutline />
              </i>
              <span className="links_name">Deals</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contactrequest">
              <i>
                <VscGitPullRequest />
              </i>
              <span className="links_name">Contact Request</span>
            </NavLink>
          </li>
        </ul> */}
      </div>

      {/* header */}

      <section className="home-section">
        <nav>
          <div className="sidebar-button">
            <i className="fa-solid fa-bars" onClick={toggleSidebar}></i>
            <span className="dashboard">Dashboard</span>
          </div>

          <div className="search-box" style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                padding: "8px",
                width: "200px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                width:"100%"
              }}
            />
            <i className="bx bx-search"></i>

            {/* Search Results Dropdown */}
            {filteredLinks.length > 0 && (
              <ul
                className="search-results"
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  width: "100%",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1000,
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                }}
              >
                {filteredLinks.map((link, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectSearch(link.path)}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #f1f1f1",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>{link.icon}</span>
                    {link.name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div
            className="profile-details"
            onClick={handleLogout}
            style={{
              fontSize: "20px",
              cursor: "pointer",
              backgroundColor: "whitesmoke",
            }}
          >
            <div>
              <MdLogout />
              <span className="ps-1">Logout</span>
            </div>
          </div>
        </nav>
        <div className="home-content">{children}</div>
      </section>
      
    </div>
  );
};
