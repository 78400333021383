import axios from "axios";
import { Base_Url } from "../constant/Url";

export const getAllOurBrands = async () => {
  try {
    const res = await fetch(`${Base_Url}/ourbrands/getallourbrands`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const createOurBrands = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/ourbrands/createourbrands`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating our brands:", error);
    throw error;
  }
};

export const getSingleOurBrands = async (id) => {
  try {
    const res = await fetch(`${Base_Url}/ourbrands/getsingleourbrands/${id}`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const DeleteSingleOurBrand = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/ourbrands/deletesingleourbrands/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleOurBrands = async (updateOurBrands_id, formData) => {
  try {
    const response = await axios.put(
      `${Base_Url}/ourbrands/updatesingleourbrands/${updateOurBrands_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating Our Brands:", error);
    throw error;
  }
};
