import axios from "axios";
import { Base_Url } from "../constant/Url";

export const createOnlineStore = async (formData) => {
  try {
    const response = await axios.post(
      `${Base_Url}/onlinestore/createonlinestore`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating Online Store:", error);
    throw error;
  }
};

export const getAllOnlineStore = async () => {
  try {
    const res = await fetch(`${Base_Url}/onlinestore/getallonlinestore`, {
      method: "Get",
      headers: {
        "Content-type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const GetSingleOnlineStore = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/onlinestore/getsingleonlinestore/${id}`,
      {
        method: "Get",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};

export const UpdateSingleOnlineStore = async (
  updateOnlineStore_id,
  formData
) => {
  try {
    const response = await axios.put(
      `${Base_Url}/onlinestore/updatesingleonlinetore/${updateOnlineStore_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating FAQ:", error);
    throw error;
  }
};

export const DeleteSingleOnlineStore = async (id) => {
  try {
    const res = await fetch(
      `${Base_Url}/onlinestore/deletesingleonlinetore/${id}`,
      {
        method: "Delete",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
