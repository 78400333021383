import React from "react";
import { Sidebar } from "./Sidebar";

const CommonLayoutAdmin = ({ children }) => {
  return (
    <>
      <Sidebar children={children} />
    </>
  );
};

export default CommonLayoutAdmin;
